import React from "react";
import AnimatedOnView from "../../AnimatedOnView";
import './life-cycle.css'
import {getImgUrl} from "../../../../functions";


class Element extends React.Component {

    state = {};

    render() {
        return <div>
            <div className={'mp-inline lc-first-block'}>
                {this.props.index !== 5 &&
                <div className={'lc-hide'} style={{ height: '150px', width: '1px', backgroundColor: 'grey', float: 'right', marginRight: '41px'}}></div>}
                {this.props.index === 5 &&
                <div className={'lc-hide'} style={{ height: '150px', float: 'right', marginRight: '41px'}}></div>}

                <div className={'lc-image-border'}>
                    <img alt={""} className={'lc-hide'}  src={this.props.data.img} width={'83px'} height={'83px'}/>
                    <img alt={""} className={'lc-phone'} src={this.props.data.img} width={'45px'} height={'45px'}/>
                </div>

            </div>
            <AnimatedOnView id={'life-cycle' + this.props.index}
                            deffClass={'mp-inline life-cycle'}
                            animatedClass={"anim-from-right-to-left2"}>
                <div className={'lc-description'}>
                    <div className={'lc-hide'} style={{fontWeight: 'bold', padding: '5px 0', fontSize: '20px'}}>{this.props.data.title}</div>
                    <div className={"mobile-description"} onClick={()=>this.changeShow(this.props.index)}>
                        <div style={{fontWeight: 'bold', padding: '5px 0', fontSize: '15px'}}>{this.props.data.title}</div>
                        <img alt={""} className={!this.state['class' + this.props.index] ? "lc-open-btn" : "lc-close-btn"} src={getImgUrl("media/open.svg")}/>
                    </div>
                    <div className={this.state['class' + this.props.index] ? 'lc-mobile-price-show' : 'lc-mobile-price'} style={{fontSize: '14px'}}>{this.props.data.info}</div>
                </div>
            </AnimatedOnView>
            <div className={'clear'}/>
        </div>
    }

    changeShow = (index) => {
        this.setState({['class' + index]: !this.state['class' + index]})
    }
}

export default Element