import React from "react";
import {i18n} from "../../../../localization";
import {PORTFOLIO_PATH} from "../../../../constants";
import Button from "../../Button/Button";
import './portfolio.css'

import AnimatedOnView from "../../AnimatedOnView";
import {getPath} from "../../../../functions";

class PortfolioBlock extends React.Component {


    render() {
        const {portfolioIMG} = this.props.data;
        return <div className={'mp-portfolio'}>
            <AnimatedOnView id="portfolioTitle">
                <h1 style={{textAlign: 'center'}}>{i18n.mainPage.portfolioBlock.title}</h1>
            </AnimatedOnView>
            <a href={getPath(PORTFOLIO_PATH)}>
            <div style={{paddingTop: '15px'}} className={"mp-inline"}>
                <AnimatedOnView id={'portfolioFirstImg'}
                                deffClass={'mp-inline mp-pb-img portfolio-g first-img left-top-border-radius'}
                                animatedClass={"anim-from-left-to-right"}>
                    <img alt={""} src={portfolioIMG[0]}/>
                </AnimatedOnView>
                <AnimatedOnView id={'portfolioSecondImg'}
                                deffClass={'mp-inline mp-pb-img portfolio-v second-img right-top-border-radius'}
                                animatedClass={"anim-from-right-to-left"}>
                    <img alt={""} src={portfolioIMG[1]}/>
                </AnimatedOnView>
            </div>
            <div >
                <AnimatedOnView id={'portfolio3Img'}
                                deffClass={'mp-inline  mp-pb-img portfolio-v first-img left-bt-border-radius-not-phone'}
                                animatedClass={"anim-from-left-to-right"}>
                    <img alt={""} src={portfolioIMG[2]}/>
                </AnimatedOnView>
                <AnimatedOnView id={'portfolio4Img'}
                                deffClass={'mp-inline  mp-pb-img portfolio-g second-img right-bt-border-radius-not-phone'}
                                animatedClass={"anim-from-right-to-left"}>
                    <img alt={""} src={portfolioIMG[3]}/>
                </AnimatedOnView>
            </div>
            <div className={"only-phone"} >
            <div className={"mp-inline"} style={{overflow: 'hidden', width: '100vw'}}>
                <AnimatedOnView id={'portfolio5Img'}
                                deffClass={'mp-inline mp-pb-img portfolio-g first-img left-bt-border-radius'}
                                animatedClass={"anim-from-left-to-right"}>
                    <img alt={""} src={portfolioIMG[4]}/>
                </AnimatedOnView>
                <AnimatedOnView id={'portfolio6Img'}
                                deffClass={'mp-inline mp-pb-img portfolio-v second-img right-bt-border-radius'}
                                animatedClass={"anim-from-right-to-left"}>
                     <img alt={""} src={portfolioIMG[5]}/>
                </AnimatedOnView>
            </div>
            </div>
            </a>
            <div style={{padding: '25px'}}>
                <AnimatedOnView id={'portfolioBtnShowAll'}>
                    <div className={'text-center pb-mp-btn'}>
                        <Button label={i18n.mainPage.portfolioBlock.button}
                                link={getPath(PORTFOLIO_PATH)}/>
                    </div>
                </AnimatedOnView>
            </div>
        </div>
    }
}

export default PortfolioBlock;