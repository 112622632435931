import React from "react";
import {i18n} from "../../../../localization";
import Element from "./Element";
import {getImgUrl} from "../../../../functions";
import AnimatedOnView from "../../AnimatedOnView";

const sickle = [
    {
        img: getImgUrl('web-image/lc1.png'),
        title: i18n.mainPage.lifeSickle.info.first.title,
        info: i18n.mainPage.lifeSickle.info.first.info
    },{
        img: getImgUrl('web-image/lc2.png'),
        title: i18n.mainPage.lifeSickle.info.second.title,
        info: i18n.mainPage.lifeSickle.info.second.info
    },{
        img: getImgUrl('web-image/lc3.png'),
        title: i18n.mainPage.lifeSickle.info.third.title,
        info: i18n.mainPage.lifeSickle.info.third.info
    },{
        img: getImgUrl('web-image/lc4.png'),
        title: i18n.mainPage.lifeSickle.info.fourth.title,
        info: i18n.mainPage.lifeSickle.info.fourth.info
    },{
        img: getImgUrl('web-image/lc5.png'),
        title: i18n.mainPage.lifeSickle.info.five.title,
        info: i18n.mainPage.lifeSickle.info.five.info
    },{
        img: getImgUrl('web-image/lc6.png'),
        title: i18n.mainPage.lifeSickle.info.six.title,
        info: i18n.mainPage.lifeSickle.info.six.info
    },
];

class LifeSickle  extends React.Component {

    render() {
        return <div className={'mp-lc'}>
            <AnimatedOnView id="lifeCycleTitle">
                <h1 style={{padding: '25px'}}>{i18n.mainPage.lifeSickle.title}</h1>
            </AnimatedOnView>
            {sickle.map((item, index) => {
                return <Element data={item} key={index} index={index}/>
            })}
        </div>
    }
}

export default LifeSickle