import React from "react";
import Button from "../../Button/Button";
import {i18n} from "../../../../localization";
import './tariff.css'
import {getImgUrl, getPath} from "../../../../functions";
import {TARIFF_PATH, TEST_PATH} from "../../../../constants";
import AnimatedOnView from "../../AnimatedOnView";

class Tariff extends React.Component {

    render() {
        return  <AnimatedOnView id={'tariffBlock' + this.props.id}
                                deffClass={this.props.cst ? "mp-tb-tariff-constructor mp-tb-tariff mp-inline" : 'mp-tb-tariff mp-inline'}
                                animatedClass={this.props.cst ? "anim-from-left-to-right2" : "anim-from-right-to-left2"}>


            <div className={'mp-tariff-title'} style={{cursor: 'auto'}}>{this.props.title}</div>
            {this.props.cst &&
            <div className={'mp-tariff-info'}>{i18n.mainPage.tariffBlock.constructorInfo}</div>}
            {!this.props.cst && <div className={'mp-tariff-info'}>{this.props.info}</div>}
            {/*{!this.props.cst &&<div className={'mp-tariff-title'}>{this.props.price}</div>}*/}

            {this.props.cst && <div className={'prev-checkbox'}
                                    style={{fontSize: '16px', padding: '2px', height: '150px'}}>{this.props.info.map((p, index) => {
                                        return this.renderCheckBox(p, index)
            })}</div>}
            {!this.props.cst && <div className={'tariff-test-img'} ><img alt={""} src={getImgUrl("web-image/Picsart.png")}></img></div>}

            <div className={'text-center mp-btn'}
                 style={{top:  '7%' , width: '250px'}}>
                {this.props.cst &&<Button label={i18n.mainPage.tariffBlock.buttonOrder} small={true} link={getPath(TARIFF_PATH)}/>}
                {!this.props.cst &&<Button label={i18n.mainPage.tariffBlock.buttonTest} small={true} link={getPath(TEST_PATH)}/>}
            </div>

        </AnimatedOnView>
    }

    renderCheckBox = (item, index) => {
        return <label key={index + 'check-box-prev'} className={" clc-checkbox"}>{item}
            <input type="checkbox" checked={true}
                   disabled={true}                   />
            <span className="checkmark"></span>
        </label>
    }
}

export default Tariff