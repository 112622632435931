import React from 'react';
import agent, {Tariffs} from "../../../agent";
import {i18n} from "../../../localization";
import Button from 'react-bootstrap/Button';
import {imageHandler} from "../../../functions/imageHandler";
import {getImgUrl, renderTariffSettingLink} from "../../../functions";
import Multiselect from 'multiselect-react-dropdown';

class TestSetting extends React.Component {

    constructor(props) {
        super();
        if (props.staticContext) {
            this.state = {
                isLoading: false,
                title: props.staticContext.title,
                description: props.staticContext.body,
                data: props.staticContext.pageData,
                currentGroup: {},
                currentCategory: {},
                groups: [],
                categories: []
            };
        } else {
            this.state = {
                isLoading: true,
                currentCategory: {},
                currentGroup: {},
                groups: [],
                categories: []
            }

        }
    }

    // получение данных
    static fetchData() {
        return Tariffs.getGroup("TEST")
            .then(function (rs) {
                return Tariffs.getCategories()
                    .then(function (response) {
                        return {
                            title: i18n.documentTitles.rootPageName,
                            body: "",
                            pageData: rs,
                            category: response
                        };
                    })
            })

    }

    componentDidMount() {
        if (this.state.isLoading) {
            TestSetting.fetchData().then(data => {
                document.title = i18n.documentTitles.rootPageName;
                this.setState({
                    isLoading: false,
                    title: i18n.documentTitles.rootPageName,
                    description: data.body,
                    groups: data.pageData || [],
                    currentGroup: {},
                    currentCategory: {},
                    categories: data.category || []
                });
            });
        }
    }


    saveGroup = () => {
        let {name, number, id} = this.state.currentGroup;

        let groups = this.state.groups.filter(g => g.id !== id);
        if (!name || !number) {
            this.setState({groupError: true, serverError: undefined})
        } else {
            Tariffs.saveGroup({...this.state.currentGroup, groupType: 'TEST'})
                .then(rs => {
                    groups.push({...rs});
                    this.setState({
                        groups: groups,
                        groupError: false,
                        serverError: undefined,
                        currentGroup: {name: "", number: "", description: ""}
                    })
                }).catch(er => {
                this.setState({serverError: "При сохранении произошла ошибка"})
            })
        }
    }

    removeGroup = () => {
        let {id} = this.state.currentGroup;
        let groupId = id;
        let groups = this.state.groups.filter(g => g.id !== id);
        if (this.state.categories.find(c => c.group === groupId)) {
            this.setState({
                groupError: false,
                serverError: "Перед удалением группы необходимо удалить категрии которые относятся к данной группе"
            })
        } else {
            Tariffs.removeGroup(this.state.currentGroup.id)
                .then(rs => {
                    this.setState({
                        groups: groups,
                        groupError: false,
                        serverError: undefined,
                        currentGroup: {name: "", number: "", description: ""}
                    })
                }).catch(er => {
                this.setState({serverError: "При сохранении произошла ошибка"})
            })
        }
    }

    removeCategory = () => {
        let {id} = this.state.currentCategory;
        let categories = this.state.categories.filter(g => g.id !== id);
        Tariffs.removeCategory(this.state.currentCategory.id)
            .then(rs => {
                this.deleteImg();
                this.setState({
                    categories: categories,
                    categoryError: false,
                    serverCategoryError: undefined,
                    currentCategory: {
                        group: undefined,
                        name: "",
                        number: "",
                        price: "",
                        tooltip: "",
                        descriptions: "",
                        special: false
                    }
                })
            }).catch(er => {
            this.setState({serverError: "При сохранении произошла ошибка"})
        })

    }

    saveCategory = () => {
        let {group, name, number, id} = this.state.currentCategory;

        let categories = this.state.categories.filter(g => g.id !== id);
        if (!name || !number || !group || group.length === 0) {
            this.setState({categoryError: true, serverCategoryError: undefined})
        } else {
            Tariffs.saveCategory(
                {
                    ...this.state.currentCategory,
                    group: group[0].id,
                    requiredTariff: this.state.currentCategory.requiredTariff && this.state.currentCategory.requiredTariff.map(c => c.id),
                    requiredGroups: this.state.currentCategory.requiredGroups && this.state.currentCategory.requiredGroups.map(c => c.id),
                })
                .then(rs => {
                    categories.push({...rs});
                    this.setState({
                        categories: categories,
                        categoryError: false,
                        serverCategoryError: undefined,
                        currentCategory: {
                            group: undefined,
                            name: "",
                            number: "",
                            price: "",
                            tooltip: "",
                            descriptions: "",
                            special: false
                        }
                    })
                }).catch(er => {
                this.setState({serverCategoryError: "При сохранении произошла ошибка"})
            })
        }
    }

    renderGroupInput = (field, name, type) => {
        return <input name="name" type={type} className={'input'}
                      style={{color: 'black', height: '35px'}}
                      value={this.state.currentGroup[field]}
                      onChange={(e) => this.setStateValueFromInput('currentGroup', field, e.target.value)}
                      placeholder={name}/>
    }

    renderCategoryInput = (field, name, type) => {
        return <input name="name" type={type} className={'input'}
                      style={{color: 'black', height: '35px'}}
                      value={this.state.currentCategory[field]}
                      onChange={(e) => this.setStateValueFromInput('currentCategory', field, e.target.value)}
                      placeholder={name}/>
    }

    setStateValueFromInput = (obj, field, value) => {
        this.setState({
            [obj]: {
                ...this.state[obj],
                [field]: value
            }
        })
    }

    renderCalculator = () => {
        let link = "#";
        return <div>
            <h1>Шаг теста</h1>
            <hr/>
            <div className={'group-list'}>
                {this.state.groups && this.state.groups.sort((a, b) => a.number - b.number)
                    .map(g => {
                        return <div>{g.number}. <a href={link}
                                                   onClick={() => this.setState({
                                                       currentGroup: g,
                                                       groupError: false
                                                   })}>{g.name}</a></div>
                    })}
            </div>
            <div className={'group-setting'}>
                <div>
                    {this.state.groupError && <div className={'error'}>
                        Без заполненного наименования и порядка отображения сохранение не доступно
                    </div>}
                    {this.state.serverError && <div className={'error'}>{this.state.serverError}</div>}
                    {this.renderGroupInput('name', 'Название шага')}
                    {this.renderGroupInput('number', 'Порядок в котором отображается', 'number')}
                    <textarea name="userValue" className={'input'}
                              style={{color: 'black', minHeight: '100px'}}
                              value={this.state.currentGroup.description}
                              onChange={(e) => this.setStateValueFromInput('currentGroup', 'description', e.target.value)}
                              placeholder="Вопрос"/>
                    <hr/>
                    <Button onClick={this.saveGroup} variant="outline-warning">
                        Сохранить шаг
                    </Button>
                    {this.state.currentGroup.id && <Button onClick={this.removeGroup} variant="outline-warning">
                        Удалить шаг
                    </Button>}
                </div>
            </div>
            <hr/>
            <h1>Вопросы теста</h1>
            <div id={'#tariffs'}/>
            <hr/>
            <div className={'group-list'}>
                {this.state.groups && this.state.groups.sort((a, b) => a.number - b.number)
                    .map(g => {
                        return <div>{g.number}. {g.name}
                            {this.state.categories && this.state.categories
                                .filter(c => c.group === g.id)
                                .sort((a, b) => a.number - b.number)
                                .map(c => {
                                    return <div>    {g.number}.{c.number} <a href={'#tariffs'}
                                                                             onClick={() => this.setState(
                                                                                 {
                                                                                     currentCategory: {
                                                                                         ...c,
                                                                                         group: [g]
                                                                                     },
                                                                                     categoryError: false
                                                                                 }
                                                                             )}>{c.name}</a></div>
                                })
                            }
                        </div>
                    })}
            </div>
            <div className={'group-setting'}>
                <div>
                    {this.state.categoryError && <div className={'error'}>
                        Необходимо заполить шаг, наименование, порядок
                    </div>}
                    {this.state.serverCategoryError && <div className={'error'}>{this.state.serverCategoryError}</div>}
                    <Multiselect
                        singleSelect={true}
                        selectedValues={this.state.currentCategory.group}
                        onSelect={this.onSelectGroup}
                        onRemove={this.onRemoveGroup}
                        displayValue="name"
                        placeholder={"Шаг"}
                        options={this.state.groups}
                    />
                    {this.renderCategoryInput('name', 'Ответ')}
                    {this.renderCategoryInput('number', 'Порядок в котором отображается', 'number')}
                    {!this.state.currentCategory.image &&
                    <Button
                        onClick={() => imageHandler(img => this.setStateValueFromInput('currentCategory', 'image', img))}
                        variant="outline-warning">
                        Добавить изображение
                    </Button>}
                    {this.state.currentCategory.image &&
                    <div><img alt={""} src={getImgUrl(this.state.currentCategory.image)} width="150px"/>
                        <Button onClick={this.deleteImg} variant="outline-warning">
                            Удалить изображение
                        </Button>
                    </div>}
                    <hr/>
                    <Button onClick={this.saveCategory} variant="outline-warning">
                        Сохранить
                    </Button>
                    {this.state.currentCategory.id && <Button onClick={this.removeCategory} variant="outline-warning">
                        Удалить
                    </Button>
                    }
                </div>
            </div>
            <hr/>

        </div>
    }

    deleteImg = () => {
        let img = this.state.currentCategory.image;
        agent.Image.delete(img)
            .then((rs) => {
                this.setState({
                    currentCategory: {
                        ...this.state.currentCategory,
                        image: null
                    }
                })
            })
            .catch(()=>{
                this.setState({
                    currentCategory: {
                        ...this.state.currentCategory,
                        image: null
                    }
                })
            })
    };

    onSelectGroup = (selectedList, selectedItem) => {
        this.setState({
            currentCategory: {
                ...this.state.currentCategory,
                group: [selectedItem]
            }
        })
    }

    onRemoveGroup = (selectedList, removedItem) => {
        this.setState({
            currentCategory: {
                ...this.state.currentCategory,
                group: []
            }
        })
    }


    render() {
        return <div>
            {renderTariffSettingLink()}
            {this.renderCalculator()}
            <div className={'clear'}/>
            <div className={'mp-btn'}>
                <Button label={"Получить"} onClick={() => this.calculate()}/>
            </div>
        </div>;
    }

}

export default TestSetting;
