import React from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import '../suneditor.css';
import '../mobile-menu.css';
import '../styles.css';
import '../new-design.css';
import '../menu.css';

import {Route, Switch} from 'react-router-dom';
import Portfolio from "./new-design/portfolio/Portfolio";
import Contacts from "./Contacts";
import Project from "./new-design/project/Project";
import Login from "./Login";
import AddNew from "./new-design/project/AddNew";
import agent from "../agent";
import ChangeOrder from "./new-design/portfolio/ChangeOrder";
import {
    ADD_NEW_PATH, CATEGORY_PATH,
    CHANGE_ORDER_PATH,
    CONTACTS_PATH,
    EDIT_PATH,
    LOGIN_PATH,
    MAIN_PATH, OLD_TARIFF_PATH,
    PORTFOLIO_PATH,
    PORTFOLIO_SETTING_PATH,
    PROJECT_PATH,
    SINGLE_IMAGE_SETTING_PATH,
    TARIFF_PATH,
    TARIFF_SETTING_PATH, TEST_PATH, TEST_SETTING_PATH
} from "../constants";
import Menu from "./new-design/Menu";
import Main from "./new-design/main-page/Main";
import TariffCalculator from "./new-design/tariffs/TariffCalculator";
import TariffCalculatorSetting from "./new-design/tariffs/TariffCalculatorSetting";
import PortfolioSetting from "./new-design/portfolio/PortfolioSetting";
import SingleImageSetting from "./new-design/portfolio/SingleImageSetting";
import TestSetting from "./new-design/tariffs/TestSetting";
import Test from "./new-design/tariffs/Test";
import PageNotFound from "./new-design/not-found/Login";
import Prices from "./Prices";


class App extends React.Component {

    state = {windowWidth: window.innerWidth};

    componentDidMount() {
        agent.User.authorized()
            .then(rs => {
                this.setState({authorized: rs})
            })
    }

    render() {
        return (
            <div>
                <Menu admin={this.state.authorized}/>
                <div className={'menu-padding'}/>
                <Switch>
                    <Route exact={true} path={MAIN_PATH}
                           render={() => <Main admin={this.state.authorized}/>}/>
                    <Route exact={true} path={PORTFOLIO_PATH}
                           render={() => <Portfolio admin={this.state.authorized}/>}/>
                    <Route exact={true} path={OLD_TARIFF_PATH}
                           render={() => <Prices admin={this.state.authorized}/>}/>
                    <Route exact={true} path={TARIFF_SETTING_PATH}
                           render={() => <TariffCalculatorSetting admin={this.state.authorized}/>}/>
                    <Route exact={true} path={TARIFF_PATH}
                           render={() => <TariffCalculator admin={this.state.authorized}/>}/>
                    <Route exact={true} path={CONTACTS_PATH}
                           render={() => <Contacts admin={this.state.authorized}/>}/>
                    <Route exact={true} path={PROJECT_PATH}
                           render={() => <Project admin={this.state.authorized} />}/>
                    <Route exact={true} path={CATEGORY_PATH}
                           render={() => <Project admin={this.state.authorized} />}/>
                    <Route exact={true} path={EDIT_PATH}
                           render={() => <AddNew admin={this.state.authorized} addNew={false} />}/>
                    <Route exact={true} path={ADD_NEW_PATH}
                           render={() => <AddNew admin={this.state.authorized}  addNew={true}/>}/>
                    <Route exact={true} path={CHANGE_ORDER_PATH}
                           render={() => <ChangeOrder admin={this.state.authorized}/>}/>
                    <Route exact={true} path={PORTFOLIO_SETTING_PATH}
                           render={() => <PortfolioSetting admin={this.state.authorized}/>}/>
                    <Route exact={true} path={TEST_SETTING_PATH}
                           render={() => <TestSetting admin={this.state.authorized}/>}/>
                    <Route exact={true} path={TEST_PATH}
                           render={() => <Test admin={this.state.authorized}/>}/>
                    <Route exact={true} path={SINGLE_IMAGE_SETTING_PATH}
                           render={() => <SingleImageSetting admin={this.state.authorized}/>}/>
                    <Route exact={true} path={LOGIN_PATH}
                           component={Login}/>
                    <Route exact={true} path={"*"}
                           component={PageNotFound}/>
                </Switch>
            </div>
        );
    }
}


export default App;
