import React from 'react';
import {CONSENT_TO_PROCESSING_PERSONAL_DATA_LINK, isClientSide, Tariffs} from "../../../agent";
import {i18n} from "../../../localization";
import Button from "../Button/Button";
import {MAIN_PATH, TARIFF_SETTING_PATH, TEST_PATH} from "../../../constants";
import {closeBtn, getImgUrl, getPath, updateDescription, updateTitle} from "../../../functions";
import './calculator.css'
import ArrowNext from "../main-page/ArrowNext";
import {MaskedInput, createDefaultMaskGenerator} from 'react-hook-mask';
import Footer from "../footer/Footer";
import TariffDescription from "./TariffDescription";
import InfoIcon from "../common/info/InfoIcon";

const maskGenerator = createDefaultMaskGenerator('+7 (999) 999-99-99');

const question = <svg width="40px" height="40px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12" r="10" stroke="#1C274C" stroke-width="1.5"/>
    <path d="M10.125 8.875C10.125 7.83947 10.9645 7 12 7C13.0355 7 13.875 7.83947 13.875 8.875C13.875 9.56245 13.505 10.1635 12.9534 10.4899C12.478 10.7711 12 11.1977 12 11.75V13" stroke="#1C274C" stroke-width="1.5" stroke-linecap="round"/>
    <circle cx="12" cy="16" r="1" fill="#1C274C"/>
</svg>;

export const areaValues = [
    {id: 'value1', value: "менее 20 м<sup>2</sup>"},
    {id: 'value2', value: "20-40 м<sup>2</sup>"},
    {id: 'value3', value: "40-100 м<sup>2</sup>"},
    {id: 'value4', value: "более 100 м<sup>2</sup>"}
];
export const buildingType = [
    {id: 'Kvartira', value: "Квартира"},
    {id: 'Dom', value: "Дом"},
    {id: 'Offis', value: "Общественное помещение"}
];

class TariffCalculator extends React.Component {

    state = {type: 'Квартира'};

    constructor(props) {
        super();
        if (props.staticContext) {
            this.state = {
                isLoading: false,
                data: props.staticContext.pageData,
                currentStep: 0,
                type: 'Кваритра',
                projectArea: '20-40 м<sup>2</sup>'
            };
        } else {
            this.state = {
                isLoading: true
            }
        }

    }

    // получение данных
    static fetchData() {
        return Tariffs.getTariff("TARIFF")
            .then(function (response) {
                return {
                    title: i18n.documentTitles.calculatorPageName,
                    description: i18n.documentTitles.calculatorDescription,
                    pageData: response
                };
            })
    }

    componentDidMount() {
        if (this.state.isLoading) {
            TariffCalculator.fetchData().then(data => {
                updateTitle(data.title);
                updateDescription(data.description);
                this.setState({
                    isLoading: false,
                    data: data.pageData,
                    currentStep: 0,
                    type: 'Квартира',
                    projectArea: '20-40 м<sup>2</sup>'
                });
                this.prepareData(data.pageData);
            });
        }

    }

    prepareData = (data) => {
        let set = new Set(data.map(d => d.group));
        let sortedGroup = Array.from(set).sort((a, b) => a - b);
        let group = new Map();
        let ind = 1;
        sortedGroup.forEach((g, index) => {
            let find = data.find(d => d.group === g && d.groupImage);
            group.set(index, find && {
                image: find.groupImage,
                id: find.group,
                name: find.groupName,
                groupDescription: find.groupDescription
            })
        });
        sortedGroup.forEach((g, index) => {
            let find = data.find(d => d.group === g && d.groupImage);
            let savedData = this.state.data;
            savedData.filter(d => d.group === g).sort((a, b) => a.orderNumber - b.orderNumber).forEach(d => {
                d.descInx = ind;
                if (g !== 4) {
                    ind = ind + 1 + d.descriptions.length;
                }
            });
        });

        this.setState({groups: sortedGroup, groupMap: group})

    }

    renderStep = (stepIndex) => {
        let {data, groupMap} = this.state;
        if (!groupMap) {
            return <div></div>
        }
        let step = groupMap.get(stepIndex);
        let colorBlack = '#2a2a2a';
        let colorOrange = '#fab30d';
        return <div>
            <div key={"group" + stepIndex} id={"group" + stepIndex} className={`black`}>
                <div>
                    <div className={'calculator-img'} id={"next-page" + stepIndex}>
                        <div className={'calculator-img-border1'}>
                            <div className={'calculator-img-border2'}>
                                {step && step.image && <img alt={""} src={getImgUrl(step.image)}/>}
                            </div>
                        </div>
                        <div>
                            <div className={"calc-previous"} id={"previous" + stepIndex}
                                 onClick={() => this.previousClick(stepIndex, stepIndex - 1)}>
                                <div style={{
                                    color: stepIndex !== 0 ? colorOrange : colorBlack,
                                    rotate: '270deg',
                                    fontSize: '20px'
                                }}>&nbsp;Назад
                                </div>
                                <ArrowNext color={stepIndex !== 0 ? colorOrange : colorBlack}/>
                            </div>
                            <div className={"calc-next"} onClick={() => this.nextClick(stepIndex, stepIndex + 1)}>
                                <div style={{
                                    color: this.state.groups.length - 1 !== stepIndex ? colorOrange : colorBlack,
                                    rotate: "90deg",
                                    fontSize: '20px'
                                }}>Далее&nbsp;</div>
                                <ArrowNext
                                    color={this.state.groups.length - 1 !== stepIndex ? colorOrange : colorBlack}/>
                            </div>
                        </div>
                    </div>
                    <div className={'calculator-options'}>
                        <div className={"calculator-options-values"}>
                            {data.filter(d => d.group === step.id).sort((a, b) => a.orderNumber - b.orderNumber).map((d, i) => {
                                return <div className={d.descriptions.length === 0 ? "pb-chb-mobile-10" : ""}>
                                    <div>{this.renderCheckBox(d, 'white-border')}</div>
                                    {d.descriptions && d.descriptions.map((desc, ind) => {
                                        return <div
                                            className={'tariff-description'}>{desc}{d.group !== 4 && <InfoIcon
                                            onClick={() => this.openDescription(d.descInx + 1 + ind)}/>}</div>
                                    })}
                                </div>
                            })}
                        </div>
                        <div className={'calculator-options-button mp-btn desctop'}>
                            <Button label={"РАССЧИТАТЬ"} small={true} onClick={() => this.calculatePrice()}/>
                        </div>
                    </div>
                    <div className={'calculator-next-step'}>
                        <div className={"calculator-prev"}>
                            {stepIndex !== 0 && <div className={'calculator-cell'} style={{fontSize: '25px'}}>
                                <div className={'clc-arrow-next'} style={{rotate: '180deg'}}>
                                    <ArrowNext link={'#tariffStep' + (stepIndex - 1)}/>
                                </div>
                                <a href={'#tariffStep' + (stepIndex - 1)}>НАЗАД</a>
                            </div>}
                        </div>
                        <div className={`${stepIndex === 0 ? "calculator-current" : "calculator-current-big"}`}>
                            <div className={'calculator-cell'}>
                                <div style={{width: '250px'}}/>
                                {/*<div>Пройди тест и мы поможем тебе с подбором услуг</div>*/}
                                {/*<div className={'mp-btn'} style={{paddingLeft: "22px"}}>*/}
                                {/*    <Button label={"Пройти"} small={true} link={getPath(TEST_PATH)}/>*/}
                                {/*</div>*/}
                            </div>

                        </div>
                        <div className={"calculator-next"}>
                            {this.state.groups.length - 1 !== stepIndex &&
                            <div className={'calculator-cell'} style={{fontSize: '25px'}}>
                                <a href={'#tariffStep' + (stepIndex + 1)}>ДАЛЕЕ</a>
                                <div className={'clc-arrow-next'}>
                                    <ArrowNext link={'#tariffStep' + (stepIndex + 1)}/>
                                </div>
                            </div>}
                        </div>

                    </div>
                    <div className={'mp-btn phone'}>
                        <Button label={"РАССЧИТАТЬ"} small={true} onClick={() => this.calculatePrice()}/>
                    </div>
                </div>
                <div id={"tariffStep" + (stepIndex + 1)}/>
            </div>
        </div>
    }

    renderResult = () => {
        let {data} = this.state;
        return <div key={"groupResult"} className={`black`}>
            <div>
                <div className={'calculator-options result-selected'}>
                    {!this.state.onlyCalculation && <div>
                        {this.state.totalPrice && <div className={"calculator-price phone"}><b>Ваша
                            цена:</b> {this.state.totalPrice} р/м<sup>2</sup>
                        </div>}
                        {this.state.singles && this.state.singles.length > 0 && this.state.singles.map(s => {
                            return <div className={"calculator-price phone"}>{s}</div>
                        })}
                    </div>}
                    {this.state.onlyCalculation && <div className={"calculator-price phone"}>
                        <b>Ваша
                            цена:</b>
                        <table style={{width: "100%"}}>
                            <tr>
                                <th style={{textAlign: "center", fontSize: "19px"}}>по Симферополю</th>
                                <th style={{textAlign: "center", fontSize: "19px"}}>по Крыму</th>
                            </tr>
                            <tr>
                                <td style={{textAlign: "center", fontSize: "19px"}}>3000 руб. +50р/м<sup>2</sup></td>
                                <td style={{textAlign: "center", fontSize: "19px"}}>6000 руб. +50р/м<sup>2</sup></td>
                            </tr>
                        </table>
                    </div>}
                    {data.filter(d => d.selected || d.special).sort((a, b) => {
                        if (a.group !== b.group) {
                            return a.group - b.group
                        } else {
                            return a.orderNumber - b.orderNumber
                        }
                    }).map((d, i) => {
                        return <div>
                            <div className={d.descriptions.length === 0 ? "pb-chb-mobile-10" : ""}>
                                {this.renderCheckBox(d, 'white-border', true)}</div>
                            {d.descriptions && d.descriptions.map((desc, ind) => {
                                return <div
                                    className={'tariff-description'}>{desc} {d.group !== 4 && <InfoIcon
                                    onClick={() => this.openDescription(d.descInx + 1 + ind)}/>}</div>
                            })}
                        </div>
                    })
                    }</div>
                <div className={'calculator-next-step'} style={{width: "50vw", verticalAlign: 'top', padding: '10px'}}>
                    {!this.state.onlyCalculation && <div className={"calculator-price desctop"}>
                        {this.state.totalPrice && <div><b>Ваша цена:</b> {this.state.totalPrice} руб/м<sup>2</sup>
                        </div>}
                        {this.state.singles && this.state.singles.length > 0 && this.state.singles.map(s => {
                            return <div className={"calculator-price desctop"}>{s}</div>
                        })}
                    </div>}
                    {this.state.onlyCalculation && <div className={"calculator-price desctop"}>
                        <b>Ваша
                            цена:</b>
                        <table style={{width: "100%"}}>
                            <tr>
                                <th style={{textAlign: "center"}}>по Симферополю</th>
                                <th style={{textAlign: "center"}}>по Крыму</th>
                            </tr>
                            <tr>
                                <td>3000 руб. +50р/м<sup>2</sup></td>
                                <td>6000 руб. +50р/м<sup>2</sup></td>
                            </tr>
                        </table>
                    </div>}
                    {this.renderForm(1)}

                    <div className={"row"}>

                    </div>
                </div>
                <div className={'phone'}>
                    {this.renderForm(2)}
                </div>
            </div>
        </div>
    }

    renderForm = (key) => {

        if (this.state.requestSended) {
            return <div className={'request-sended'}>Мы получили Ваше обращение и в ближайшее время свяжемся с Вами
                <br/>
                <a href={getPath(MAIN_PATH)}>На главную</a>
            </div>
        }

        return <div key={"form" + key}>
            <form id={'order-form'}>
                <div>
                    <input type="text" name="userName" size="40" className={'input'}
                           onChange={(e) => this.setState({name: e.target.value, nameError: false})}
                           aria-required="true" aria-invalid={!this.state.name}
                           placeholder="Имя"/>
                    {this.state.nameError && <div className={"error"}>{i18n.error.nameRequired}</div>}
                </div>
                <div className={"phone-input"}>
                    <MaskedInput
                        placeholder="+7 (999) 999-99-99"
                        maskGenerator={maskGenerator}
                        value={this.state.value}
                        onChange={(phone) => this.setState({phone: phone, phoneError: false})}
                    />
                    {this.state.phoneError && <div className={"error"}>{i18n.error.phoneError}</div>}
                </div>
                {/*<div>*/}
                {/*    <input type="number" name="userPhone" size="40" className={'input'}*/}
                {/*           onChange={(e) => this.setState({projectArea: e.target.value, nameError: false})}*/}
                {/*           aria-required="true" aria-invalid={!this.state.projectArea}*/}
                {/*           placeholder="Введите площадь помещения"/>*/}
                {/*</div>*/}
                <div className={'area'}>
                    <div className={'calculator-radio'}>
                        {areaValues.map(e => this.renderRadio(e, 'projectArea'))}
                    </div>
                </div>
                <div className={'calculator-radio'}>
                    {buildingType.map(e => this.renderRadio(e, 'type'))}
                </div>
                <div className={'phone-btn'}>
                    <div className={'text-center ft-btn small-height result-button-calc'}>
                        <Button label={"Получить бесплатную консультацию"} onClick={() => this.sendRequest()}/>
                    </div>
                    <div className={'text-center ft-btn result-button-calc'}>
                        <Button onClick={() => this.setState({confirmWindow: false})} label={"Назад"}/>
                    </div>
                </div>
                <div style={{fontSize: '15px', textAlign: 'center', paddingTop: '10px'}}
                     className={'personal-data-style'}>
                    Нажимая на кнопку, я соглашаюсь на&nbsp;
                    <a href={CONSENT_TO_PROCESSING_PERSONAL_DATA_LINK}
                       target={"_blank"}>обработку персональных данных</a>.
                </div>
            </form>
        </div>
    }

    renderRadio = (e, field) => {
        return <label htmlFor={e.id}
                      className={this.state[field] === e.value ? "checked" : ""}
                      onClick={() => this.setState({[field]: e.value})}>
            {this.state[field] === e.value && <input type="radio"
                                                     id={e.id}
                                                     name={field}
                                                     checked
                                                     value={e.id}/>}
            {this.state[field] !== e.value && <input type="radio"
                                                     id={e.id}
                                                     name={field}
                                                     value={e.id}/>}
            &nbsp;<span dangerouslySetInnerHTML={{__html: e.value}}/>
        </label>
    }

    sendRequest = () => {
        if (!this.state.phone || !this.state.name || this.state.phone.length < 10) {
            this.setState({nameError: !this.state.name, phoneError: !this.state.phone || this.state.phone.length < 10});
            return;
        }
        let data = {
            projectArea: this.state.projectArea,
            tariffIds: this.state.data.filter(i => i.selected || i.special).map(i => i.id),
            phone: this.state.phone,
            name: this.state.name,
            type: this.state.type,
            calculatedPrice: this.state.totalPrice + "\n" + this.state.singles.toString()
        };
        Tariffs.calculatePrice(data)
            .then(rs => {
                this.setState({requestSended: true})
                // this.saveFile(rs.data, 'application/pdf', "fileName");
            })
    }

    renderCalculator = () => {
        let {groups} = this.state;
        return <div>
            <div className={'calculator-all-step-conteiner'}>
                <div className={'calculator-all-step'}>
                    {groups && groups.map((g, index) => {
                        return <div>
                            <div className={`${index === 0 ? "calculate-tariff-step" : "calculate-tariff-step-big"}`}>
                                {this.renderStep(index)}
                            </div>
                        </div>
                    })}
                </div>
            </div>
            <div className={"calculator-confirm-btn phone"}>
                {/*<div className={'mp-btn phone'}>*/}
                {/*    <Button label={"ПРОЙТИ ТЕСТ"} link={getPath(TEST_PATH)}/>*/}
                {/*</div>*/}
            </div>
        </div>
    }

    getLinkHref = (index) => {
        if (this.state.groups.length === index || index === 0) {
            return "#"
        }
        return "#"
    }

    previousClick = (index, indexTo) => {
        if (!isClientSide() || this.state.groups.length === indexTo || indexTo === -1) {
        } else {
            document.getElementById("previous" + indexTo)
                .scrollIntoView({behavior: "smooth", block: "nearest", inline: "nearest"})
        }
    }
    nextClick = (index, indexTo) => {
        if (!isClientSide() || this.state.groups.length === indexTo || indexTo === -1) {

        } else {
            document.getElementById("next-page" + indexTo)
                .scrollIntoView({behavior: "smooth", block: "nearest", inline: "nearest"})
        }
    }


    openDescription = (index) => {
        this.setState({showDescription: true, popUpIndex: index},
            () => {
                if (isClientSide()) {
                    if (document.getElementById("desc-popUp-" + index)) {
                        document.getElementById("desc-popUp-" + index)
                            .scrollIntoView({behavior: "auto", block: "start", inline: "nearest"})
                    }
                }
            })
    }

    renderConfirmWindow = () => {
        return <div>
            <div>
                <div
                    className={"calculate-tariff-result"}>{this.renderResult()}</div>
            </div>
        </div>
    }

    renderCheckBox = (item, style, disavled, updateModal) => {
        return <span><label key={item.id + "chb-label"} className={style + " clc-checkbox"}>
            <input key={item.id + "chb-input"} type="checkbox"
                   defaultChecked={item.selected || item.special || item.modalSelected}
                   checked={item.selected || item.special || item.modalSelected}
                   disabled={item.special || disavled}
                   onChange={(e) => updateModal ? item.modalSelected = !item.modalSelected : this.selectTariff(item.id)}/>
            <span className="checkmark"></span>
        </label>
            <div style={{display: 'inline-block'}}
                 className={'tariff-description'}>
                {item.name}{item.special && <sup>*</sup>}
                {item.tooltip && <div className={style + " tooltipleft"}>
                    <div style={{marginTop: '-2px', fontSize: '11px'}}>?</div>
                    <span className="toolttextleft">{item.tooltip}</span>
                </div>}
                <InfoIcon onClick={() => this.openDescription(item.descInx)}/>
            </div>

            </span>
    }

    renderCheckBoxForGroup = (groupId, style, name) => {
        return <label className={style + " clc-checkbox"}> {name}
            <input type="checkbox"
                   checked={this.state.data && this.state.data.filter(d => d.group === groupId).every(d => d.selected || d.special)}
                   onChange={(e) => this.recalculatePriceOnGroupSelected(groupId)}/>
            <span className="checkmark"></span>
        </label>
    }

    recalculatePriceOnGroupSelected = (groupId) => {
        let tariffs = this.state.data.filter(d => d.group === groupId);
        if (tariffs.every(d => d.selected || d.special)) {
            tariffs.forEach(d => this.selectTariff(d.id))
        } else {
            tariffs.filter(d => !d.selected).forEach(d => this.selectTariff(d.id))
        }
    }

    renderModal = () => {
        if (!this.state.confirmModal) {
            return <div></div>
        }
        let id = this.state.currentSelect;
        let current = this.state.data.find(i => i.id === id);
        let priceList = this.state.data.filter(i => i.id !== id);
        let requiredItems = [];
        current.requiredItem && current.requiredItem.forEach(req => {
            let filter = priceList.find(i => i.id === req && !i.selected && !i.special);
            if (filter) {
                if (filter.group !== current.group) {
                    filter.modalSelected = true;
                    requiredItems.push(filter)
                }
            }
        });
        let requiredItemsFromGroup = [];
        current.requiredGroups && current.requiredGroups.forEach(req => {
            if (!priceList.find(i => i.groupId === req && i.selected)) {
                priceList
                    .filter(i => i.groupId === req)
                    .forEach(p => requiredItemsFromGroup.push(p));
            }
        })
        let name = current.name;
        if (current.name.length > 30) {
            name = name.substr(0, 30) + "...";
        }
        return <div className={'modal-br'}>
            <div className={"calculator-modal"}>
                <h1 style={{textAlign: 'center'}}>Подтверждение</h1>
                <span className={"close-modal"}> {closeBtn(() => {
                    let result = this.state.data.filter(i => !i.modalSelected);
                    this.state.data.filter(i => i.modalSelected)
                        .forEach(i => {
                            result.push({...i, modalSelected: false, selected: false});
                        });
                    isClientSide() && document.body.classList.remove('modal-open');
                    this.setState({confirmModal: false, data: result});
                }, "close-calculator", true)}</span>
                {requiredItems.length > 0 && <div>Для выбора <b>"{name}"</b> является обязательным выбор: <br/>
                    {requiredItems.map(d => this.renderCheckBox(d, 'white-border', true,))}
                    <hr/>
                </div>}
                {requiredItemsFromGroup.length > 0 && <div className={"confirm-options"}>Необходимо выбрать один из:
                    {requiredItemsFromGroup.map(d => this.renderCheckBox(d, 'white-border', false, true))}
                    <hr/>
                </div>}
                <div style={{paddingTop: "10px", paddingBottom: "10px", borderTop: "1px solid #ffffff26"}}>Подтвердите
                    действие для выбора указанных категорий.
                </div>
                <div className={"calculator-cell phone-btn modal-confirm-btn"}>
                    <div className={'mp-btn result-button-calc'}>
                        <Button label={"OK"} small={true} onClick={() => {
                            let result = this.state.data.filter(i => !i.modalSelected);
                            this.state.data.filter(i => i.modalSelected)
                                .forEach(i => {
                                    result.push({...i, modalSelected: false, selected: true});
                                });
                            isClientSide() && document.body.classList.remove('modal-open');
                            this.setState({confirmModal: false, data: result})
                        }}/>
                    </div>
                    <div className={'mp-btn result-button-calc'}>
                        <Button label={"ОТМЕНА"} small={true} onClick={() => {
                            let result = this.state.data.filter(i => !i.modalSelected);
                            this.state.data.filter(i => i.modalSelected)
                                .forEach(i => {
                                    result.push({...i, modalSelected: false, selected: false});
                                })
                            isClientSide() && document.body.classList.remove('modal-open');
                            this.setState({confirmModal: false, data: result})
                        }}/>
                    </div>
                </div>
            </div>
        </div>
    }

    selectTariff = (id) => {
        let current = this.state.data.find(i => i.id === id);

        let priceList = this.state.data.filter(i => i.id !== id);
        if (!current.selected) {
            current.requiredItem && current.requiredItem.forEach(req => {
                let filter = priceList.find(i => i.id === req && !i.selected && !i.special);
                if (filter) {
                    if (filter.group === current.group) {
                        filter.selected = true;
                    } else {
                        current.modalSelected = true;
                        isClientSide() && document.body.classList.add('modal-open');
                        this.setState({confirmModal: true, currentSelect: id})
                    }
                }
            });
            current.requiredGroups && current.requiredGroups.forEach(req => {
                let fromRequiredGroup = priceList.find(i => id !== i.id && i.groupId === req && i.selected);
                if (!fromRequiredGroup) {
                    current.modalSelected = true;
                    isClientSide() && document.body.classList.add('modal-open');
                    this.setState({confirmModal: true, currentSelect: id})
                }
            })
            current.selected = !current.selected;
        } else {
            this.uncheck(current);
        }

        priceList.push(current);

        this.setState({data: priceList})
    }

    calculatePrice = () => {
        let totalPrice = 0;
        let singles = [];
        let onlyCalculation = false;
        let prices = this.state.data.filter(i => i.selected || i.special);
        if (prices) {
            if (prices.length === 1 && prices.find(i => i.special || i.price === 0)) {
                totalPrice = prices[0].price;
                onlyCalculation = true;
            } else {
                singles = prices.filter(i => i.single).map(i => `${i.name} ${i.price}${i.singleDescription}`);
                if (prices.filter(i => !i.special && !i.single).length) {
                    totalPrice = prices.filter(i => !i.special && !i.single).map(s => s.price)
                        .reduce((accumulator, currentValue) => accumulator + currentValue);
                } else {
                    totalPrice = undefined;
                }
            }
        }
        if (isClientSide()) {
            window.scrollTo(0, 0);
        }
        this.setState({totalPrice: totalPrice, singles: singles, onlyCalculation: onlyCalculation, confirmWindow: true})
    }

    uncheck = (current) => {
        let id = current.id;
        current.selected = false;
        let priceList = this.state.data.filter(i => i.id !== id);
        let filter = priceList.filter(p => p.requiredItem && p.requiredItem.indexOf(current.id) !== -1)
            .filter(p => p.selected);
        priceList.filter(p => p.requiredItem && p.requiredItem.indexOf(current.id) !== -1)
            .filter(p => p.selected)
            .forEach(p => this.uncheck(p));
        let group = current.groupId;
        let selectedItemInGroup = priceList.find(p => p.id !== id && p.groupId === group && p.selected);
        if (!selectedItemInGroup) {
            priceList.filter(p => p.requiredGroups && p.requiredGroups.indexOf(group) > -1)
                .filter(p => p.selected)
                .forEach(p => this.uncheck(p));
        }
        let result = [];
        this.state.data
            .forEach(i => {
                result.push({...i, updated: Date.now()});
            });
        this.setState({data: result})
    }

    renderSettingLink = () => {
        let admin = this.props.admin;
        return <div>
            {admin && <a href={getPath(TARIFF_SETTING_PATH)}>{i18n.button.tariffSettingLink}</a>}
        </div>
    }

    render() {
        return <div>
            <div className={'calculator-top'} id={"tariffStep0"}>
                {!this.state.confirmWindow &&
                <div className={'calculator-title'}><h1>{i18n.calculator.title}</h1></div>}
                {!this.state.confirmWindow && <div className={'calculator-title'}>
                    <h1 style={{fontSize: '17px'}}>{i18n.calculator.titleHint}</h1>
                </div>}
            </div>
            {this.renderSettingLink()}
            <div className={this.state.confirmWindow ? "display-none" : ""}>{this.renderCalculator()}</div>
            {this.state.confirmWindow && this.renderConfirmWindow()}
            {this.renderModal()}
            {<div className={'btn-open-pop-up'} onClick={()=>this.setState({showDescription: true})}>{question}</div>}
            {this.state.showDescription && <div className={'pop-up-br'}/>}
            <TariffDescription showDescription={this.state.showDescription}
                               popUpIndex={this.state.popUpIndex}
                               hide={() => this.setState({showDescription: false})}/>
            <div className="clear"></div>
            <div className={"desctop"}><Footer/></div>
        </div>;
    }

    saveFile(data, type, fileName) {
        let file = new Blob([data], {type: type});
        let fileURL = URL.createObjectURL(file);
        if (typeof window.navigator.msSaveBlob === "undefined") {
            this.setState({fileURL: fileURL})
        } else {
            window.navigator.msSaveBlob(file, fileName);
        }
    }
}

export default TariffCalculator;
