import agent from "../agent";

export const imageHandler = (afterLoad) => {
    const input = document.createElement('input');

    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = async () => {
        const file = input.files[0];
        const formData = new FormData();
        formData.append('content', file);
        const res = await agent.Image.save(formData);
        afterLoad(res.replace(/"/g, ""))
    }
}