import React from "react";
import {getImgUrl} from "../../../functions";


class WorkBlock extends React.Component{
    render() {
        return <div className={'mp-inline mp-work-block'}>
            <img alt={""} width={'30px'} src={getImgUrl('web-image/' + this.props.img)}/>
            <div style={{color: 'white'}}>{this.props.text}</div>
        </div>
    }
}
export default WorkBlock;