import React from 'react';
import {getImgUrl} from "../../../functions";
import {isClientSide} from "../../../agent";

class TariffDescription extends React.Component {

    state = {};
    constructor(props) {
        super();
    }

    render() {
        let className  = this.props.showDescription == undefined
            ? "tariff-popup-description-inactive" :
            this.props.showDescription ? 'tariff-popup-description'
                : 'tariff-popup-description-hide';
        if (this.state.fullScreen) {
            className = 'pop-up-full-screen ' + className;
        }

        return <div className={className}>
            <span className="icon-close-content support-iframe-close icon" onClick={() => {
                this.setState({fullScreen: false});
                this.props.hide()
            }}/>
            <span className="icon-open-new-page support-open-full icon" onClick={() => {
                this.setState({fullScreen: true},
                    () => {
                        if (isClientSide() && this.props.popUpIndex) {
                            if (document.getElementById("desc-popUp-" + this.props.popUpIndex)) {
                                document.getElementById("desc-popUp-" + this.props.popUpIndex)
                                    .scrollIntoView({behavior: "auto", block: "start", inline: "nearest"})
                            }
                        }
                    })
            }}/>
            <div className={'tariff-popup-description-content'}>
            <h4 style={{textAlign: 'center'}} id={"desc-popUp-1"}>Обмерный план с привязкой инженерных коммуникаций</h4>
            <p>План, на котором с точностью до пяти сантиметров указываются длина, ширина и высота каждого
                помещения, несущие и ненесущие стены, размеры оконных и дверных проемов, отмечается наличие и
                расположение
                инженерных коммуникаций: вентиляционных шахт, стояков водоснабжения и канализации, электрических щитков,
                пожарных датчиков, стояков и щитов отопления.</p>

            <p className={'pop-up-image'}>
                <img width={'100%'} alt={""} src={getImgUrl('tariff-description/1.png')}/>
            </p>


            <h4 style={{textAlign: 'center'}} id={"desc-popUp-2"}>Варианты планировочных решений (не менее 2-х вариантов)</h4>
            <p>На базе ТЗ и обмерного плана создаются несколько вариантов планировочных решений, которые отличаются друг
                от
                друга и в совокупности позволяют заказчику оценить потенциал его квартиры или дома.</p>
            <p><img width={'100%'} alt={""} src={getImgUrl('tariff-description/2.png')}/></p>

            <h4 style={{textAlign: 'center'}} id={"desc-popUp-3"}>Итоговый вариант планировочного решения с расстановкой мебели</h4>
            <p>На базе сразу всех планировочных решений собирается итоговый план. Происходит окончательное проставление
                размеров, расстановка мебели, кухонного оборудования конкретных габаритов и т.д.</p>
            <p><img width={'100%'} alt={""} src={getImgUrl('tariff-description/3.png')}/></p>

            <h4 style={{textAlign: 'center'}} id={"desc-popUp-4"}>План демонтажа и монтажа существующих конструкций</h4>
            <p>В плане указываются все идущие под снос участки стен и о возводимых стенах, и их расположение.
                Указываются
                размеры подиумов и создаваемых ниш, а также материалы утеплителя и шумоизоляции. При необходимости
                отмечается замена оконных, входных и внутренних дверных блоков, батарей отопления, а для вторичного
                жилья
                указывается удаление всех старых отделочных материалов. Благодаря плану строители получают полное
                представление о предстоящем фронте работ и рассчитывается количество строительных материалов.</p>
            <p><img width={'100%'} alt={""} src={getImgUrl('tariff-description/4.png')}/></p>

            <h4 style={{textAlign: 'center'}} id={"desc-popUp-5"}>План помещения после перепланировки</h4>
            <p>План дает представление о том, как будут выглядеть квартира или дом после перепланировки: длина и ширина,
                площадь и экспликация всех помещений, дверные и оконные блоки. На основе плана согласовывается
                перепланировка в соответствующих инстанциях, подбирается подходящая по габаритам мебель и рассчитывается
                точное количество отделочных материалов, а в будущем чертеж пригодится хозяевам, если они захотят
                сделать
                косметический ремонт или обновить меблировку.</p>
            <p><img width={'100%'} alt={""} src={getImgUrl('tariff-description/5.png')}/></p>
            <h4 style={{textAlign: 'center'}} id={"desc-popUp-6"}>План размещения санитарно-технического и кухонного оборудования</h4>
            <p> План необходим для подводки всех коммуникаций водоснабжения и водоотведения как в ванных комнатах и
                санузлах, так и на кухне, в том числе и для соответствующих бытовых приборов. Указывается точная
                привязка
                расположения сантехнического оборудования, смесителей, полотенцесушителей, водонагревателей, фильтров и
                т.д.</p>
            <p><img width={'100%'} alt={""} src={getImgUrl('tariff-description/6.png')}/></p>

            <h4 style={{textAlign: 'center'}} id={"desc-popUp-7"}>Схема укладки напольных покрытий</h4>
            <p>Чертёж показывает виды, количество и рисунок укладки используемых напольных покрытий, а также наличие и
                уровни расположения ступенек и подиумов. Также в плане показаны линии перехода между различными
                покрытиями.
                По данной схеме рассчитывается спецификация напольных покрытий для проекта.</p>
            <p><img width={'100%'} alt={""} src={getImgUrl('tariff-description/7.png')}/></p>

            <h4 style={{textAlign: 'center'}} id={"desc-popUp-8"}>План размещения теплого пола</h4>
            <p>Теплые полы укладываются строго на расстоянии в 10-15 сантиметров от стен, мебели и сантехнических
                приборов,
                под керамическую плитку, камень или керамогранит – эти материалы обладают прекрасной теплопроводностью,
                что
                позволяет обеспечить максимальную функциональность готовой конструкции. На чертеже указывается
                месторасположение и площадь теплых полов, дается привязка по стенам и обязательно приводится схема
                установки
                терморегуляторов.</p>
            <p>Необходимо помнить, что в многоквартирных жилых домах разрешено применение только электрических моделей,
                поэтому план размещения теплого пола в подобных случаях является неотъемлемой составляющей проекта по
                электроснабжению. Для частных домов возможно также устройство водяного теплого пола.</p>
            <p><img width={'100%'} alt={""} src={getImgUrl('tariff-description/8.png')}/></p>
            <h4 style={{textAlign: 'center'}} id={"desc-popUp-9"}>Схема дверных проемов</h4>
            <p>Чертеж включает данные по расположению на объекте всех дверных проемов, с указанием размеров для каждого
                из
                них, а также содержит уточнения и примечания, которые необходимы для оформления заказа и последующей
                правильной установки дверных блоков. Кроме того, на плане даются схемы открывания дверей и, при
                необходимости, приводится их устройство – например, для устанавливаемых откатных конструкций.</p>
            <p><img width={'100%'} alt={""} src={getImgUrl('tariff-description/9.png')}/></p>
            <h4 style={{textAlign: 'center'}} id={"desc-popUp-10"}>План размещения светильников с привязками</h4>
            <p><img width={'100%'} alt={""} src={getImgUrl('tariff-description/10.png')}/></p>

            <h4 style={{textAlign: 'center'}} id={"desc-popUp-11"}>Схема подключения осветительных приборов</h4>
            <p><img width={'100%'} alt={""} src={getImgUrl('tariff-description/11.png')}/></p>
            <h4 style={{textAlign: 'center'}} id={"desc-popUp-12"}>План размещения розеток, выключателей и электровыводов</h4>
            <p><img width={'100%'} alt={""} src={getImgUrl('tariff-description/12.png')}/></p>
            <h4 style={{textAlign: 'center'}} id={"desc-popUp-13"}>План потолка</h4>
            <p>На плане потолков показывается его вид, размеры ниш, а также необходимые технические особенности
                опускания потолка и, например, для монтажа вентиляции или кабельного кондиционирования, установки
                светильников.</p>
            <p><img width={'100%'} alt={""} src={getImgUrl('tariff-description/12.png')}/></p>
            <h4 style={{textAlign: 'center'}} id={"desc-popUp-14"}> Концептуальный мудборд</h4>
            <p>Задача коллажей – выявить стилистические и цветовые предпочтения хозяев квартиры или дома, обозначить
                круг их вкусов и привычек в плане интерьера, мебели и декора, а также обрисовать атмосферу и настроение,
                которые заказчик хотел бы видеть в каждом отдельном помещении. 2d коллажи выполняются в нескольких
                вариантах, с использованием заметно отличающихся по цветовому решению и декоративным элементам. Вместе с
                коллажами могут обсуждаться и примеры готовых интерьеров, это также помогает точнее определиться с
                пожеланиями заказчика.</p>
            <p><img width={'100%'} alt={""} src={getImgUrl('tariff-description/14.png')}/></p>

            <h4 style={{textAlign: 'center'}} id={"desc-popUp-15"}> Реалистичный 3d коллаж</h4>
            <p>Интерьерный 3d коллаж - это картинка будущего интерьера, которая дает визуальное представление о
                стилистике проекта в существующих габаритах, с использованием реальной мебели, светильников, отделочных
                материалов и элементов декора. Также такой коллаж используют для косметического ремонта. Его можно
                выполнить на основе фотографии помещения для замены отдельных отделочных материалов, мебели,
                осветительных приборов и т.д. Благодаря чему можно посмотреть каким будет Ваш интерьер до покупки
                материалов или мебели.</p>
            <p><img width={'100%'} alt={""} src={getImgUrl('tariff-description/15.jpg')}/></p>

            <h4 style={{textAlign: 'center'}} id={"desc-popUp-16"}> 3d визуализация</h4>
            <p>3D-визуализации выполняется в нескольких ракурсах и представляет собой фотореалистичное изображения
                будущего интерьера помещения, которые позволяют задолго до окончания ремонта наглядно увидеть
                предполагаемый результат. Вместе с 3D-визуализации могут обсуждаться и примеры готовых интерьеров, это
                также помогает точнее определиться с пожеланиями заказчика.</p>
            <p><img width={'100%'} alt={""} src={getImgUrl('tariff-description/16.jpg')}/></p>

            <h4 style={{textAlign: 'center'}} id={"desc-popUp-17"}>Виртуальный тур 360</h4>
            <p>3D тур показывает заказчику всё пространство вокруг себя, может приближать и отдалять изображение, и
                переходить от панорамы к панораме - от одной точки съемки к другой - от помещения к помещению.
                Для демонстрации одного небольшого помещения, в большинстве случаев требуется одна 3D панорама.
                Исключением является съемка больших или зонированных помещений. В панораме, эффект присутствия ярче и
                больше похож на реальную прогулку по помещению, и более понятно, как пространство выглядит в жизни.</p>

            <h4 style={{textAlign: 'center'}} id={"desc-popUp-18"}>Развертки стен всех помещений с просчетом и раскладкой материалов</h4>
            <p>На развёртках указываются высоты помещения, розеток, выключателей, электровыводов, мебели относительно
                стен и ее габаритах, а также о расположении всех планируемых декоративных элементов – фотообоев, панно,
                зеркал, стеновых панелей по каждой стене. Также на основании разверток строители впоследствии будут
                выполнять финальный этап ремонтных работ – отделку стен. Заказчику же развертки дают наглядное
                представление о том, какое количество отделочных материалов потребуется.</p>
            <p><img width={'100%'} alt={""} src={getImgUrl('tariff-description/17.png')}/></p>
            <h4 style={{textAlign: 'center'}} id={"desc-popUp-19"}>Спецификации с рекомендациями</h4>
            <p>В спецификации описывается название мебели, сантехники, осветительных приборов, декора и т.д. Дается его
                изображение, размеры, количество, цена и указывается помещение, в котором будет использован данный
                предмет. Необходимо учитывать, что спецификация даёт рекомендательный характер и может быль изменена в
                процессе покупки и пожеланий заказчика.</p>
            <p><img width={'100%'} alt={""} src={getImgUrl('tariff-description/18.png')}/></p>
            </div>
        </div>
    }

}

export default TariffDescription;