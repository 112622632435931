import React from 'react';
import {getToken, Projects} from "../../../agent";
import {getImgUrl, getPath, updateDescription, updateTitle} from "../../../functions";
import {i18n} from "../../../localization";
import {
    CATEGORY_LABEL,
    PORTFOLIO_SETTING_PATH,
    PROJECT_LABEL
} from "../../../constants";
import './portfolio.css'
import Footer from "../footer/Footer";
import AnimatedOnView from "../AnimatedOnView";
import {arrowNext} from "../main-page/ArrowNext";

class Portfolio extends React.Component {
    state = {};

    constructor(props) {
        super();
        if (props.staticContext) {
            this.state = {
                isLoading: false,
                title: props.staticContext.title,
                description: props.staticContext.body,
                data: props.staticContext.pageData,
                categories: props.staticContext.categories
            };
        } else {
            this.state = {
                isLoading: true,
                title: '',
                description: ''
            };
        }
    }

    // получение данных
    static fetchData(token) {
        return Projects.getProjects(token)
            .then(function (response) {
                return {
                    title: i18n.documentTitles.rootPageName,
                    body: "",
                    pageData: response && response.projectInputs
                };
            }).then(function (data) {
                return Projects.getImageCategories()
                    .then(function (categories) {
                        return {
                            ...data,
                            categories: categories
                        }
                    } )
            }
        )
    }

    componentDidMount() {
        if (this.state.isLoading) {
            Portfolio.fetchData(getToken()).then(data => {
                this.setState({
                    isLoading: false,
                    title: i18n.documentTitles.rootPageName,
                    description: data.body,
                    data: data.pageData,
                    categories: data.categories
                });
            });
        }
        updateTitle(i18n.documentTitles.portfolioTitles);
        updateDescription(i18n.documentTitles.portfolioDescription)
    }

    renderAdminLink = () => {
        const {admin} = this.props;
        return <div>
            {admin &&<div  className={'inline-block admin-link'}>
                <a href={getPath(PORTFOLIO_SETTING_PATH)} small={true}>{i18n.portfolio.setting}</a>
            </div>}
        </div>
    };

    renderItems = () => {
        return (
            <div>
                <h1 style={{textAlign: 'center', paddingTop: '35px'}}>{i18n.menu.portfolio}</h1>
                {this.state.categories && <div className={"sub-menu category-sub-menu-container"}>
                    <a className={"category-sub-menu-item prev"}>{arrowNext}</a>
                    <a className={"category-sub-menu-item next"}>{arrowNext}</a>
                    {this.state.categories.sort((a, b) => a.number - b.number).map(c => <a className={"nav-link category-sub-menu-item"} href={getPath(CATEGORY_LABEL + "/" + c.id)}>{c.name}</a>) }

                </div>}
                {this.renderAdminLink()}
                <div>
                    {this.state.data && this.state.data.map((item, index) => {
                        return this.renderItem(item, index);
                    })}
                </div>
            </div>
        );
    };

    renderItem = (item, index) => {
        let projectLink = getPath(`${PROJECT_LABEL}/${item.url}`);
        return <div key={"project" + index}>
            <div className={'project-box'}>
                <div className={'box pointer project-img'}>
                    <a href={projectLink}>
                        <img src={getImgUrl(item.image)} height={"10%"} alt={item.title}/>
                    </a>
                </div>
            </div>

            <AnimatedOnView href={projectLink}
                            link={true}
                            id={"link" + index}
                            deffClass={'project-box-hover'}
                            animatedClass={"project-box-hover-phone"}>
                <div className={'box pointer project-img-fon'}/>
                <div className={"info"}>
                    <div className={"project-title"}>{item.title}</div>
                    <div className={"line"}/>
                    <div className={"read-more"}>{i18n.portfolio.showMoreLabel}<span className="arr"/></div>
                </div>
            </AnimatedOnView>
        </div>
    };

    render() {
        return <div>
            {this.renderItems()}
            <div className="clear"></div>
            <Footer/>
        </div>;
    }
}

export default Portfolio;
