import React from 'react';
import {Projects} from "../../../agent";
import {i18n} from "../../../localization";
import Button from 'react-bootstrap/Button';
import { renderAdminLink, updateTitle} from "../../../functions";



class PortfolioSetting extends React.Component {

    constructor(props) {
        super();
        if (props.staticContext) {
            this.state = {
                isLoading: false,
                description: props.staticContext.body,
                data: props.staticContext.pageData,
                categories: props.categories
            };
        } else {
            this.state = {
                isLoading: true,
                categories: [],
                currentCategory: {}
            }

        }
    }

    // получение данных
    static fetchData() {
        return Projects.getImageCategories()
            .then(function (rs) {
                return {
                    title: i18n.documentTitles.portfolioSettingName,
                    body: "",
                    categories: rs
                };
            })

    }

    componentDidMount() {
        if (this.state.isLoading) {
            updateTitle(i18n.documentTitles.portfolioSettingName);
            PortfolioSetting.fetchData().then(data => {
                this.setState({
                    isLoading: false,
                    description: data.body,
                    categories: data.categories || [],
                    currentCategory: {}
                });
            });
        }
    }


    saveCategory = () => {
        let {name, number, id} = this.state.currentCategory;
        let categories = this.state.categories.filter(g => g.id !== id);
        if (!name || !number) {
            this.setState({error: "Заполните поля"})
        } else {
            Projects.saveCategory(this.state.currentCategory)
                .then(rs => {
                    categories.push({...rs});
                    this.setState({
                        categories: categories,
                        error: undefined,
                        currentCategory: {name: "", number: ""}
                    })
                }).catch(er => {
                this.setState({error: "При сохранении произошла ошибка"})
            })
        }
    }

    removeCategory = () => {
        let {id} = this.state.currentCategory;
        let categories = this.state.categories.filter(g => g.id !== id);

        Projects.removeCategory(this.state.currentCategory.id)
            .then(rs => {
                this.setState({
                    categories: categories,
                    error: undefined,
                    currentCategory: {name: "", number: ""}
                })
            }).catch(er => {
            this.setState({serverError: "При сохранении произошла ошибка"})
        })

    }


    renderInput = (field, name, type) => {
        return <input name="name" type={type} className={'input'}
                      style={{color: 'black', height: '35px'}}
                      value={this.state.currentCategory[field]}
                      onChange={(e) => this.setStateValueFromInput('currentCategory', field, e.target.value)}
                      placeholder={name}/>
    }

    setStateValueFromInput = (obj, field, value) => {
        this.setState({
            [obj]: {
                ...this.state[obj],
                [field]: value
            }
        })
    }

    renderSettings = () => {
        let categories = this.state.categories;
        let link = '#';
        return <div>
            <h1 style={{textAlign: 'center'}}>НАСТРОЙКИ КАТЕГОРИЙ ИЗОБРАЖЕНИЙ</h1>
            <hr/>
            <div className={'categories-list'}>
                {categories && categories.sort((a, b) => a.number - b.number)
                    .map(c => {
                        return <div>{c.number}. <a href={link}
                                                   onClick={() => this.setState({
                                                       currentCategory: c,
                                                       error: undefined
                                                   })}>{c.name}</a></div>
                    })}
            </div>
            <div className={'categories-setting'}>
                <div>
                    {this.state.error && <div className={'error'}>{this.state.error}</div>}
                    {this.renderInput('name', 'Наименование')}
                    {this.renderInput('number', 'Порядок в котором отображается', 'number')}

                    {/*{!this.state.currentGroup.image &&*/}
                    {/*<Button*/}
                    {/*    onClick={() => imageHandler(img => this.setStateValueFromInput('currentGroup', 'image', img))}*/}
                    {/*    variant="outline-warning">*/}
                    {/*    Добавить изображение группы*/}
                    {/*</Button>}*/}
                    {/*{this.state.currentGroup.image &&*/}
                    {/*<div><img src={getImgUrl(this.state.currentGroup.image)} width="150px"/>*/}
                    {/*    <Button onClick={this.deleteImg} variant="outline-warning">*/}
                    {/*        Удалить изображение группы*/}
                    {/*    </Button>*/}
                    {/*</div>}*/}
                    <hr/>
                    <Button onClick={this.saveCategory} variant="outline-warning">
                        Сохранить категорию
                    </Button>
                    {this.state.currentCategory.id && <Button onClick={this.removeGroup} variant="outline-warning">
                        Удалить категорию
                    </Button>}
                </div>
            </div>
        </div>
    }

    render() {
        return <div>
            {renderAdminLink()}
            {this.renderSettings()}
        </div>;
    }

}

export default PortfolioSetting;
