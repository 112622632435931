import React from "react";
import {i18n} from "../../../../localization";
import Button from "../../Button/Button";
import WorkBlock from "../WorkBlock";
import ArrowNext from "../ArrowNext";
import {PORTFOLIO_ID, TARIFF_ID} from "../../../../constants";
import './first-block.css'
import {getImgUrl} from "../../../../functions";


class FirstBlok  extends React.Component {
    render() {
        return <div className={'first-block'}>
            <div className={"mp-video-block"} >
                <video id="bgVideo" className={'video'}
                       pip="false"
                       poster={getImgUrl('poster.jpg')}
                       autoPlay loop muted>
                    <source src={getImgUrl('video.mp4')} type="video/mp4"/>
                </video>
            </div>
            <div className={'mp-background mp-video-block'}/>
            <div className={'mp-block'}>
                <div className={'text-center text-white mp-title'}>{i18n.mainPage.firstBlock.title}</div>
                <div className={'text-center text-white mp-info'}>{i18n.mainPage.firstBlock.info}</div>
                <div className={'text-center mp-btn mp-bt-clt'}><Button label={i18n.button.calculate} link={'#'+ TARIFF_ID}/></div>
            </div>
            <div className={'mp-arrow-next'}>
                <ArrowNext link={'#'+ PORTFOLIO_ID}/>
            </div>

            <div className={'mp-button-block'}>
                <WorkBlock img={'icon-word.png'} text={i18n.mainPage.firstBlock.workBlock.developProject}/>
                <WorkBlock img={'icon-bad.png'} text={i18n.mainPage.firstBlock.workBlock.createProject}/>
                <WorkBlock img={'icon-work.png'} text={i18n.mainPage.firstBlock.workBlock.selectMaterials}/>
                <WorkBlock img={'icon-doc.png'} text={i18n.mainPage.firstBlock.workBlock.term}/>
            </div>
            <div id={PORTFOLIO_ID} className={"portfolio-id-link"} />
        </div>

    }
}

export default FirstBlok