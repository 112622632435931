import React from 'react';
import {updateTitle} from "../functions";
import Footer from "./new-design/footer/Footer";
import {i18n} from "../localization";

class Contacts extends React.Component {

    componentDidMount() {
        updateTitle(i18n.documentTitles.contactTitles);
    }

    render() {
        return <div>
            <div className="clear"></div>
            <Footer isContact={true}/>
        </div>;
    }
}

export default Contacts;
