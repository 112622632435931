import React from 'react';
import Container from 'react-bootstrap/Container'
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import agent, {Projects} from "../../../agent";
import {closeBtn, getImgUrl, renderAdminLink} from "../../../functions";
import IconButton from '@material-ui/core/IconButton';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import CheckIcon from '@material-ui/icons/Check';
import Snackbar from '@material-ui/core/Snackbar';
import {imageHandler} from "../../../functions/imageHandler";
import CloseIcon from "@material-ui/icons/Close";
import Multiselect from 'multiselect-react-dropdown';

function validated() {

}

let gorizontMap = new Map();
let tagMap = new Map();
let categoryMap = new Map();
let currentImageToChange = null;

class AddNew extends React.Component {
    state = {images: [], orderNumber: 0};

    componentDidMount() {
        let href = document.location.href;
        let isEdit = !this.props.addNew;
        this.setState({isEdit: isEdit});
        if (isEdit) {
            this.getProjectDataByGuid(href.substring(href.lastIndexOf("/")));
            this.setState({open: true, message: 'Идет пересчет ориентации изображений, до окончания пересчета не нужно сохранять проект'})

        } else {
            this.setState({description: " "})
        }

    }

    getProjectDataByGuid = (guid) => {
        gorizontMap = new Map();
        Projects.getTags()
            .then(rs => {
                let tags = rs.map(i => (i.value));
                tags.sort( (a, b) => {
                    var nameA = a.toUpperCase();
                    var nameB = b.toUpperCase();
                    if (nameA < nameB) {
                        return -1;
                    }
                    if (nameA > nameB) {
                        return 1;
                    }
                    return 0;
                });
                this.setState({tags: tags});
            });
        Projects.getImageCategories()
            .then(rs=> this.setState({categories: rs}))
            .then(() => Projects.getProjectByGuid(guid))
            .then(rs => rs.data)
            .then(projectDataByGuid => {
                let main = projectDataByGuid.images.filter(i => i.main).map(i => i.image);
                if (main.length > 0) {
                    main = main[0]
                } else if (projectDataByGuid.images.length > 0) {
                    main = projectDataByGuid.images[0].image
                } else {
                    main = undefined;
                }
                projectDataByGuid.images.forEach(i => {
                    let tags = i.tags ? i.tags.split(",") : [];
                    if (tags.length === 1 && tags[0] === '') {
                        tags = [];
                    }
                    tagMap.set(i.image, tags)
                })
                projectDataByGuid.images.forEach(i => {
                    let categories = i.categories || [];
                    let filter = this.state.categories.filter(c => categories.indexOf(c.id.toString()) !== -1);
                    categoryMap.set(i.image, filter)
                })
                this.setState({
                    ...projectDataByGuid,
                    images: projectDataByGuid.images.map(i => i.image),
                    mainImg: main
                });
            });
    };

    saveTag = (img) => {
        let tagImgInput = {image: img, tags: this.getTagString(img)};
        agent.Project.saveTags(tagImgInput);
    }

    getTagString = (img) => {
        let tagsValues = tagMap.get(img);
        let tagString = '';
        if (tagsValues) {
            tagsValues.forEach((i, index) => {
                if (index === tagsValues.length - 1) {
                    tagString = tagString + i;
                } else {
                    tagString = tagString + i + ','
                }
            })
        }
        return tagString;
    }

    deleteImg = () => {
        let img = this.state.planImg;
        agent.Image.delete(img)
            .then((rs) => {
                this.setState({
                    planImg: null, open: true,
                    message: "Изображение удалено"
                })
            })
            .catch(()=>{
                this.setState({
                    planImg: null
                })
            })
    };

    saveProject = () => {
        let mainImg = this.state.mainImg;
        let project = {
            id: this.state.id,
            title: this.state.title,
            metaTitle: this.state.metaTitle,
            metaDescription: this.state.metaDescription,
            description: this.state.description,
            orderNumber: this.state.orderNumber,
            planImg: this.state.planImg,
            images: this.state.images.map((img, index) => {
                let category = categoryMap.get(img) || [];
                return {
                    image: img,
                    tags: this.getTagString(img),
                    main: img === mainImg,
                    gorizontal: gorizontMap.get(img),
                    categories: category.map(c => c.id)
                }
            })
        };

        let promis = this.state.isEdit ? agent.Project.update : agent.Project.save;
        promis(project)
            .then((rs => {
                this.setState({id:rs});
                this.setState({isEdit: true});
                this.setState({open: true, message: 'Успешно сохранено'})
            }));
    }

    form = () => {
        const handleSubmit = (event) => {
            if (this.state.setTagForImage) {
                event.stopPropagation();
                event.preventDefault();
                this.addNewTagToState()
                return
            }
            const form = event.currentTarget;
            if (form.checkValidity() === true) {

            }
            event.preventDefault();
            event.stopPropagation();

        };
        let elementById = document.getElementById('container');

        let innerWidth = elementById ? elementById.clientWidth : 750;

        let windowWidth = innerWidth * 0.94;
        let count = 4;
        let widthOneBlock = windowWidth / count;

        console.info(1)
        return (
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <div style={{paddingTop: '40px'}}/>
                <Form.Row>
                    <Form.Group as={Col} md="12" controlId="validationCustom01">
                        <Form.Control
                            required
                            type="text"
                            value={this.state.title}
                            onChange={(e) => {
                                this.setState({title: e.target.value})
                            }}
                            placeholder="Наименование"
                        />
                        <Form.Control.Feedback type="invalid">
                            Необходимо ввести наименование
                        </Form.Control.Feedback>
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} md="12" controlId="validationCustom011">
                        <Form.Control
                            type="text"
                            value={this.state.metaTitle}
                            onChange={(e) => {
                                this.setState({metaTitle: e.target.value})
                            }}
                            placeholder="Мета тег title"
                        />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} md="12" controlId="validationCustom021">
                        <textarea
                           style={{width: '100%'}}
                            value={this.state.metaDescription}
                           maxLength={1000}
                            onChange={(e) => {
                                this.setState({metaDescription: e.target.value})
                            }}
                            placeholder="Мета тег description"
                        />
                    </Form.Group>
                    <div style={{marginTop: "-20px", textAlign:'right'}}>Осталось {1000 - (!!this.state.metaDescription ? this.state.metaDescription.length : 0) } символов</div>
                </Form.Row>
                {!this.state.planImg &&
                    <Button onClick={() => imageHandler(img => this.setState({planImg: img}))} variant="outline-warning">Добавить
                        изображение плана</Button>}
                {this.state.planImg && <div><img alt={""} src={getImgUrl(this.state.planImg)} width="150px"/>
                    <Button onClick={this.deleteImg} variant="outline-warning">Удалить
                        изображение плана</Button>
                </div>}
                <div>
                {this.renderEditor()}
                </div>
                <Form.Group>
                    <input type="file" name="images" id="imgid" onChange={this.saveFile} multiple/>
                    {/*<Form.File id="exampleFormControlFile1" onChange={(e) => this.saveFile(e)}/>*/}
                </Form.Group>
                <Form.Row>
                    {this.state.images && this.state.images.map((img, index) => {
                        let gorizontal = gorizontMap.get(img);
                        let width = (gorizontal && count > 1 ? (widthOneBlock * 2 + 10) : widthOneBlock) + 'px';
                        let height = (gorizontal && count === 1 ? widthOneBlock / 2 : widthOneBlock) + 100 + 'px';
                        let style = {
                            marginLeft: '5px',
                            marginBottom: '10px',
                            width: width,
                            height: height,
                            float: "left"
                        };

                        return <div className={'box pointer visible-block'} style={style}>
                            <img alt={""} src={getImgUrl(img)}
                                 className={'visible-block'}
                                 draggable="true"
                                 onDragStart={() => this.onDragStart(img)}
                                 onDragEnd={() => this.onDragEnd(img)}
                                 onDragOver={() => this.onDragOver(img)}
                                 width={"100%"}
                                 ref={(el) => {
                                     let value = gorizontMap.get(img);
                                     if (value === undefined && el) {
                                         gorizontMap.set(img, el.naturalWidth > el.naturalHeight);
                                         if (gorizontMap.size === this.state.images.length) {
                                             this.setState({open: true, message: 'Пересчет ориентации изображений завершен'})
                                         }
                                     }
                                 }}/>

                            <div className={"invisible-block"} style={{
                                background: "rgb(183, 181, 181, 0.51)",
                                position: "relative",
                                width: "100%"
                            }}>
                                <div style={{display: "inline-block"}}><IconButton aria-label="delete"
                                                                                   onClick={() => this.deleteFile(img)}>
                                    <DeleteForeverIcon color="secondary" fontSize="large"/>
                                </IconButton>
                                </div>
                                {this.state.mainImg !== img &&
                                    <div style={{display: "inline-block"}}
                                         onClick={() => this.setState({mainImg: img})}><b>Установить главной</b></div>}
                                <br/><br/>
                                <a style={{display: "inline-block"}}
                                   onClick={() => this.setState({setTagForImage: img})}><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Указать
                                    теги</b></a>
                                <div  id={'select-in-image'} style={{paddingTop: '10px'}}>
                                <Multiselect
                                    showCheckbox={true}
                                    selectedValues={categoryMap.get(img)}
                                    onSelect={(l, v)=>{
                                        categoryMap.set(img, l)
                                    }}
                                    onRemove={(l, v)=>{
                                        categoryMap.set(img, l)
                                    }}
                                    displayValue="name"
                                    placeholder={"Категория изображения"}
                                    options={this.state.categories}
                                />
                                </div>
                            </div>

                            {this.state.mainImg === img && <IconButton aria-label="delete" style={{
                                float: 'right',
                                marginTop: '-60px',
                                background: "rgba(231,255,246,0.81)"
                            }}>
                                Главная <CheckIcon color="primary" fontSize="large"/>
                            </IconButton>}
                        </div>
                    })}
                </Form.Row>
                <div>
                    <Button variant="outline-warning" color="primary" onClick={this.saveProject}>
                        Сохранить
                    </Button>
                </div>
                {this.state.setTagForImage &&
                    <div className={'modal-tag'}>
                        <img alt={""} src={getImgUrl(this.state.setTagForImage)}
                             width={gorizontMap.get(this.state.setTagForImage) ? 300 : 150}></img>
                        <tr className={'add-tag'}>
                            <td width={'60%'}><Form.Control
                                type="text"
                                htmlSize={40}
                                value={this.state.newTag}
                                onChange={(e) => {
                                    this.setState({newTag: e.target.value});
                                }}
                                placeholder="Добавить новый тег"
                            /></td>
                            <td style={{width: '10%'}}>
                                <div onClick={this.addNewTagToState}>Добавить
                                </div>
                            </td>
                        </tr>
                        <hr/>
                        <div className={'tag-block'}><ul className="tag-list">
                            {this.state.tags && this.state.tags.map((i, index) => {
                                return this.getTagBlock(i, index)
                            })}</ul></div>
                        {closeBtn(() => {
                            this.saveTag(this.state.setTagForImage)
                            this.setState({setTagForImage: false});
                        }, "close-elem-ico")}
                    </div>}
            </Form>
        );
    };

    addNewTagToState = () => {
        if (this.state.newTag) {
            let tags = this.state.tags;
            tags.push(this.state.newTag);
            this.setState({tags: tags, newTag: ''});
        }
    }

    getTagBlock = (tag, index) => {
        let tags = tagMap.get(this.state.setTagForImage);
        if (!tags) {
            tags = [];
        }
        let selected = tags.indexOf(tag) > -1;
        return <li key={index} onClick={() => {
            if (selected) {
                tags = tags.filter(v => v !== tag)
            } else {
                tags.push(tag);
            }
            tagMap.set(this.state.setTagForImage, tags);
            this.setState({tagMap})
        }} style={
            {
                fontSize: selected ? '17px' : '14px',
                color: (selected ? 'blue' : 'black'),
                fontWeight: selected ? 'bold' : '',
                cursor: 'pointer'
            }
        }>{tag}</li>

    }

    onDragStart = (event) => {
        this.setState({dragableImage: event})
    };

    onDragEnd = () => {
        let {images, dragableImage} = this.state;
        let number = images.indexOf(dragableImage);
        let number1 = images.indexOf(currentImageToChange);
        let correct = number > number1 ? 0 : 1;
        let newImages = [];
        images.forEach((i, index) => {
            if (index !== number) {
                if (index === number1 + correct) {
                    newImages.push(dragableImage)
                }
                newImages.push(i)
            }
        });
        if (number1 === images.length - 1) {
            newImages.push(dragableImage)
        }
        this.setState({images: newImages})
    };
    onDragOver = (event) => {
        currentImageToChange = event
    };

    deleteFile = (guid) => {
        let img = this.state.images;
        agent.Image.delete(guid)
            .then((rs) => {
                img.splice(img.indexOf(guid), 1);
                this.setState({images: img})
            })
    };

    saveFile = (data) => {
        if (data.target.files.length > 0) {
            for (let i = 0; i < data.target.files.length; i++) {
                let file = data.target.files[i];
                if (file) {
                    let formData = new FormData();
                    formData.append('content', file);
                    agent.Image.save(formData)
                        .then((rs) => {
                            let img = this.state.images;
                            img.unshift(rs.replace(/"/g, ""));
                            categoryMap.set(img, []);
                            this.setState({images: img})
                        })
                }
            }
        }
    };

    renderEditor = () => {
        return this.state.description &&
            <textarea name="description" className={'input'}
                      style={{color: 'black', minHeight: '100px'}}
                      value={this.state.description}
                      onChange={(e) => this.setState({description: e.target.value})}
                      placeholder="Описание проекта"/>
    }

    render() {
        return (
            <Container id={'container'}>
                {renderAdminLink()}
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    open={this.state.open}
                    autoHideDuration={2000}
                    onClose={()=>{this.setState({open: false})}}
                    message={this.state.message}
                    action={
                        <React.Fragment>
                            <IconButton size="small" aria-label="close" color="inherit" onClick={()=>{this.setState({open: false})}}>
                                <CloseIcon fontSize="small" />
                            </IconButton>
                        </React.Fragment>
                    }
                />
                {this.form()}
            </Container>
        );
    }
}

export default AddNew;
