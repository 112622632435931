import React from 'react';
import agent, {Projects} from "../../../agent";
import {i18n} from "../../../localization";
import {getImgUrl, renderAdminLink, updateTitle} from "../../../functions";
import IconButton from "@material-ui/core/IconButton";
import Multiselect from 'multiselect-react-dropdown';
import Button from "react-bootstrap/Button";

let gorizontMap = new Map();
class SingleImageSetting extends React.Component {

    constructor(props) {
        super();
        if (props.staticContext) {
            this.state = {
                isLoading: false,
                description: props.staticContext.body,
                data: props.staticContext.pageData,
                images: props.staticContext.images,
                categories: props.staticContext.categories
            };
        } else {
            this.state = {
                isLoading: true,
                images: []
            }

        }
    }

    // получение данных
    static fetchData() {
        return Projects.getImageCategories()
            .then(function (rs) {
                return {
                    title: i18n.documentTitles.portfolioSettingName,
                    body: "",
                    categories: rs || []
                };
            }).then(function (rs) {
                return Projects.getSingleImages()
                    .then(img => {
                        img.forEach(i => {
                            let categories = i.categories || [];
                            let filter = rs.categories.filter(c => categories.indexOf(c.id.toString()) !== -1);
                            i.categories = filter;
                        });
                        return {
                            ...rs,
                            images: img || [],

                        }
                    })

            })

    }

    componentDidMount() {
        if (this.state.isLoading) {
            updateTitle(i18n.documentTitles.portfolioSettingName);
            SingleImageSetting.fetchData().then(data => {
                this.setState({
                    isLoading: false,
                    ...data
                });
            });
        }
    }


    saveFile = (data) => {
        if (data.target.files.length > 0) {
            for (let i = 0; i < data.target.files.length; i++) {
                let file = data.target.files[i];
                if (file) {
                    let formData = new FormData();
                    formData.append('content', file);
                    agent.Image.save(formData, true)
                        .then((rs) => {
                            let img = this.state.images;
                            img.unshift(rs.replace(/"/g, ""));
                            this.setState({images: img})
                        })
                }
            }
        }
    };


    renderSettings = () => {
        let elementById = document.getElementById('container');

        let innerWidth = elementById ? elementById.clientWidth : 750;

        let windowWidth = innerWidth * 0.94;
        let count = 4;
        let widthOneBlock = windowWidth / count;
        return <div id={'container'}>
            <h1 style={{textAlign: 'center'}}>НАСТРОЙКИ ИЗОБРАЖЕНИЙ БЕЗ ПРОЕКТА</h1>
            <hr/>
            <input type="file" name="images" id="imgid" onChange={this.saveFile} multiple/>
            <div className="clear"></div>
            {this.state.images && this.state.images.map((img, index) => {
                let gorizontal = gorizontMap.get(img.image);
                let width = (gorizontal && count > 1 ? (widthOneBlock * 2 + 10) : widthOneBlock) + 'px';
                let height = (gorizontal && count === 1 ? widthOneBlock / 2 : widthOneBlock) + 100 + 'px';
                let style = {
                    marginLeft: '5px',
                    marginBottom: '10px',
                    width: width,
                    height: height,
                    float: "left"
                };

                return <div className={'box pointer visible-block'} style={style}>
                    <img src={getImgUrl(img.image)}
                         alt={""}
                         className={'visible-block'}
                         draggable="true"
                         width={"100%"}
                         ref={(el) => {
                             let value = gorizontMap.get(img.image);
                             if (value === undefined && el) {
                                 gorizontMap.set(img.image, el.naturalWidth > el.naturalHeight);
                                 if (gorizontMap.size === this.state.images.length) {
                                     this.setState({open: true, message: 'Пересчет ориентации изображений завершен'})
                                 }
                             }
                         }}/>

                    <div className={"invisible-block"} style={{
                        background: "rgb(183, 181, 181, 0.51)",
                        position: "relative",
                        width: "100%"
                    }}>
                        <div style={{display: "inline-block"}}><IconButton aria-label="delete"
                                                                           onClick={() => this.deleteFile(img.image)}>
                            Удалить изображение
                        </IconButton>
                        </div>
                        <div  id={'select-in-image'} style={{paddingTop: '10px'}}>
                            <Multiselect
                                showCheckbox={true}
                                selectedValues={img.categories}
                                onSelect={(l, v)=>{
                                    img.categories = l
                                }}
                                onRemove={(l, v)=>{
                                    img.categories = l
                                }}
                                displayValue="name"
                                placeholder={"Категория изображения"}
                                options={this.state.categories}
                            />
                        </div>
                    </div>
                </div>
            })}
        </div>
    }

    render() {
        return <div>
            {renderAdminLink()}
            {this.renderSettings()}
            <div className={"clear"}/>
            <div>
                <Button variant="outline-warning" color="primary" onClick={this.saveCategory}>
                    Сохранить
                </Button>
            </div>
        </div>;
    }

    saveCategory = () => {
        Projects.saveImageSingleCategory({
            images: this.state.images.map(i => ({
                ...i,
                categories: i.categories.map(c => c.id)
            }))
        })
    }

}

export default SingleImageSetting;
