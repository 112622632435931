import React from 'react';
import {isClientSide, Tariffs} from "../../../agent";
import {i18n} from "../../../localization";
import Button from "../Button/Button";
import {MAIN_PATH, TARIFF_SETTING_PATH} from "../../../constants";
import {closeBtn, getImgUrl, getPath, updateDescription, updateTitle} from "../../../functions";
import './calculator.css'
import { MaskedInput, createDefaultMaskGenerator } from 'react-hook-mask';
import {areaValues, buildingType} from "./TariffCalculator";
import Footer from "../footer/Footer";
const maskGenerator = createDefaultMaskGenerator('+7 (999) 999-99-99');

class Test extends React.Component {

    state = {};

    constructor(props) {
        super();
        if (props.staticContext) {
            this.state = {
                isLoading: false,
                data: props.staticContext.pageData,
                currentStep: 0,
                type: 'Кваритра',
                projectArea: '20-40 м<sup>2</sup>'
            };
        } else {
            this.state = {
                isLoading: true
            }
        }

    }

    // получение данных
    static fetchData() {
        return Tariffs.getTariff("TEST")
            .then(function (response) {
                return {
                    title: i18n.documentTitles.calculatorPageName,
                    description: i18n.documentTitles.calculatorDescription,
                    pageData: response
                };
            })
    }

    componentDidMount() {
        if (this.state.isLoading) {
            Test.fetchData().then(data => {
                updateTitle(data.title);
                updateDescription(data.description);
                this.setState({
                    isLoading: false,
                    data: data.pageData,
                    currentStep: 0,
                    type: 'Кваритра',
                    projectArea: '20-40 м<sup>2</sup>'
                });
                this.prepareData(data.pageData);
            });
        }

    }

    prepareData = (data) => {
        let set = new Set(data.map(d => d.group));
        let sortedGroup = Array.from(set).sort((a, b) => a - b);
        let group = new Map();
        sortedGroup.forEach((g, index) => {
            let find = data.find(d => d.group === g);
            group.set(index, find && {
                id: find.group,
                name: find.groupName,
                groupDescription: find.groupDescription
            })
        })
        this.setState({groups: sortedGroup, groupMap: group})

    }

    select = (answer) => {
        let answers = this.state.answers;
        if (!answers) {
            answers = []
        }
        answers[this.state.currentStep] = answer;
        let currentItem = answers[this.state.currentStep + 1];
        let finished = this.state.groups.length === (this.state.currentStep + 1)
        this.setState({answers: answers, finished: finished, currentStep: this.state.currentStep + 1, currentItem: currentItem})
    }

    back = () => {
        console.info(this.state.answers, this.state.currentStep)
        let answers = this.state.answers;
        let currentItem = answers[this.state.currentStep - 1];
        this.setState({answers: answers, finished: false, currentStep: this.state.currentStep - 1, currentItem: currentItem})
    }

    renderStep = (step, currentStep) => {
        let {data} = this.state;
        if (!step) {
            return <div></div>
        }
        let dataToRender = data.filter(d => d.group === step.id)
            .sort((a, b) => a.orderNumber - b.orderNumber);
        let twoColumn = dataToRender && dataToRender.length > 6;
        let first = [];
        let second = [];
        dataToRender.forEach((i, ind) => {
            if (ind %2 === 0) {
                first.push(i);
            } else {
                second.push(i);
            }
        })
        return <div key={"group"} className={`black`}>
            <div>
                <h1 className={"test-question"}>{step.groupDescription}</h1>
                {!twoColumn && <div className={'test-options'}>
                    {dataToRender.map((d, i) => {
                        return <div>
                            {this.renderCheckBox(d, 'white-border')}
                        </div>
                    })}
                </div>}
                {twoColumn && <div style={{margin: '0 auto'}}>
                    <div className={'test-options2'}>
                    {first.map((d, i) => {
                        return <div>
                            {this.renderCheckBox(d, 'white-border')}
                        </div>
                    })}
                </div>
                    <div className={'test-options2'}>
                    {second.map((d, i) => {
                        return <div>
                            {this.renderCheckBox(d, 'white-border')}
                        </div>
                    })}
                </div>
                </div>}
                <div className={'clear'}/>

                <div className="test-result">
                <div className={'phone-btn'}>
                    <div className={'text-center ft-btn'} style={{display: 'inline-block', paddingLeft: '50px'}}>
                        {currentStep !== 0 && <Button label={"К предыдущему шагу"} onClick={this.back}/>}
                    </div>
                    <div className={'text-center ft-btn'} style={{display: 'inline-block', paddingLeft: '110px'}}>
                        <Button onClick={() => this.select(this.state.currentItem)} label={"К следующему шагу"}/>
                    </div>
                </div>
                </div>
            </div>
        </div>
    }

    renderResult = () => {
        if (this.state.requestSended) {
            return <div className={'request-sended'}>Мы получили Ваше обращение и в ближайшее время свяжемся с Вами
                <br/>
                <a href={getPath(MAIN_PATH)}>На главную</a>
            </div>
        }
        return <div key={"groupResult"} className={`black`}>
            <div>
                <div className={'test-result'} >

                    <div className={"calculator-price"}><b>Для получения результата оставте свои данные и мы свяжемся с Вами</b></div>
                    <div>
                        <input type="text" name="userName" size="40" className={'input'}
                               onChange={(e) => this.setState({name: e.target.value, nameError: false})}
                               aria-required="true" aria-invalid={!this.state.name}
                               placeholder="Имя"/>
                        {this.state.nameError && <div className={"error"}>{i18n.error.nameRequired}</div>}
                    </div>
                    <div className={"phone-input"}>
                        <MaskedInput
                            placeholder="+7 (999) 999-99-99"
                            maskGenerator={maskGenerator}
                            value={this.state.value}
                            onChange={(phone) => this.setState({phone: phone, phoneError: false})}
                        />
                        {this.state.phoneError && <div className={"error"}>{i18n.error.phoneError}</div>}
                    </div>

                    <div className={'phone-btn'}>
                        <div className={'text-center ft-btn'} style={{display: 'inline-block', paddingLeft: '50px'}}>
                            <Button label={"Получить результат"} onClick={() => this.sendRequest()}/>
                        </div>
                        <div className={'text-center ft-btn'} style={{display: 'inline-block', paddingLeft: '110px'}}>
                            <Button onClick={this.back} label={"Назад"}/>
                        </div>
                    </div>

                    <div className={"row"}>
                    </div>
                </div>
            </div>
        </div>
    }

    renderRadio = (e, field) => {
        return<label htmlFor={e.id}
                     className={this.state[field] === e.value ? "checked" : ""}
                     onClick={() => this.setState({[field]: e.value})}>
            {this.state[field] === e.value && <input type="radio"
                                                     id={e.id}
                                                     name={field}
                                                     checked
                                                     value={e.id}/>}
            {this.state[field] !== e.value && <input type="radio"
                                                     id={e.id}
                                                     name={field}
                                                     value={e.id}/>}
            &nbsp;<span dangerouslySetInnerHTML={{__html:e.value}}/>
        </label>
    }

    sendRequest = () => {
        if (!this.state.phone || !this.state.name || this.state.phone.length < 10) {
            this.setState({nameError: !this.state.name, phoneError: !this.state.phone  || this.state.phone.length < 10});
            return;
        }
        let data = {
            projectArea: this.state.projectArea,
            tariffIds: this.state.answers.map(i => i.id),
            phone: this.state.phone,
            name: this.state.name,
            type: this.state.type,
            test: true
        };
        Tariffs.calculatePrice(data)
            .then(rs => {
                this.setState({requestSended: true})
            })
    }

    renderCalculator = () => {
        let {groupMap, currentStep} = this.state;
        if (!groupMap) {
            return <div></div>
        }
        let step = groupMap.get(currentStep);
        return <div>
            <div>
                <div key={step.groupId} className={`calculate-tariff-step-big`}>
                    {this.renderStep(step, currentStep)}
                </div>
            </div>
        </div>
    }

    renderConfirmWindow = () => {
        return <div>
            <div>
                <div
                    className={"calculate-tariff-result"}>{this.renderResult()}</div>
            </div>
        </div>
    }

    renderCheckBox = (item, style) => {
        return <label className={style + " clc-checkbox"}>{item.name}
            <input type="checkbox" checked={this.state.currentItem && item.id === this.state.currentItem.id}
                   onChange={(e) => this.setState({currentItem: item})}/>
            <span className="checkmark"></span>
        </label>
    }

    renderSettingLink = () => {
        let admin = this.props.admin;
        return <div>
            {admin && <a href={getPath(TARIFF_SETTING_PATH)}>{i18n.button.tariffSettingLink}</a>}
        </div>
    }

    render() {
        return <div>
            <div className={'calculator-top'} id={"tariffStep0"}>
                {!this.state.confirmWindow &&
                <div className={'calculator-title test-title'}><h1>{i18n.calculator.testTitle}</h1></div>}
            </div>
            {this.renderSettingLink()}
            {!this.state.finished && !this.state.confirmWindow && this.renderCalculator()}
            {this.state.finished && this.renderConfirmWindow()}
            <div className="clear"></div>
            <Footer/>
        </div>;
    }

}

export default Test;
