import axios from 'axios';

export const HOST = 'https://interiormydreams.ru';
export const API_ROOT =  HOST + '/api';
export const PRIVACY_POLICY_LINK = HOST + '/static/docs/Политика_конфиденциальности.pdf';
export const CONSENT_TO_PROCESSING_PERSONAL_DATA_LINK = HOST + '/static/docs/Согласие_на_обработку_персональных_данных.pdf';

// export const ADMIN_PATH = 'http://interiormydreams.ru:7000';
// export const CLIENT_PATH = 'http://interiormydreams.ru';
//
// export const API_ROOT = 'http://localhost:8083/api';
// export const API_ROOT = 'http://192.168.0.135:8083/api';
// export const ADMIN_PATH = 'http://localhost:4105';
// export const CLIENT_PATH = 'http://localhost:4101';


const responseBody = res => res.body;

export const AUTH_URL = API_ROOT + "/auth";
export const ALL_PROJECT_GET_URL = API_ROOT + "/getAllProject";
export const ALL_TARIFF_GET_URL = API_ROOT + "/tariff";
export const CALCULATE_PRICE_URL = API_ROOT + "/calculatePrice";
export const ALL_NEWS_GET_URL = API_ROOT + "/setting/news";
export const ALL_PRICES_GET_URL = API_ROOT + "/getPrices";
export const ALL_TAG_GET_URL = API_ROOT + "/tags";
export const ALL_IMAGE_CATEGORIES = API_ROOT + "/image/categories";
export const ALL_SINGLE_IMAGE = API_ROOT + "/setting/images/single";
export const IMAGE_CATEGORIES = API_ROOT + "/setting/image/category";
export const CATEGORIES = (id) => API_ROOT + `/category${id}`;
export const GROUP_URL = API_ROOT + "/setting/group";
export const CATEGORY_URL = API_ROOT + "/setting/tariff";
export const ALL_GROUP_URL = API_ROOT + "/setting/groups";
export const ALL_CATEGORY_URL = API_ROOT + "/setting/tariffs";
export const PROJECT_BY_GUID_GET_URL = (guid) => API_ROOT + `/getProject${guid}`;

export const AUTHORIZED_GET_URL = "/authorized";

export const IMG_URL = 'https://interiormydreams.ru/static/';

export const Projects = {
    getProjects: () => axios.get(ALL_PROJECT_GET_URL, getConfig())
        .then(rs => rs.data),
    getProjectByGuid: (guid) => axios.get(PROJECT_BY_GUID_GET_URL(guid), getConfig()),
    getTags: () => axios.get(ALL_TAG_GET_URL, getConfig())
        .then(rs => rs.data),
    getImageCategories: () => axios.get(ALL_IMAGE_CATEGORIES, getConfig())
        .then(rs => rs.data),
    getSingleImages: () => axios.get(ALL_SINGLE_IMAGE, getConfig())
        .then(rs => rs.data),
    saveCategory: (data) => axios.post(IMAGE_CATEGORIES, data, getConfig())
        .then(rs => rs.data),
    saveImageSingleCategory: (data) => axios.post(ALL_SINGLE_IMAGE, data, getConfig())
        .then(rs => rs.data),
    removeCategory: (id) => axios.delete(IMAGE_CATEGORIES + "/" + id, getConfig())
        .then(rs => rs.data),
    getImageByCategories: (id) => axios.get(CATEGORIES(id), getConfig())
};

export const Tariffs = {
    getTariff: (data) => axios.get(ALL_TARIFF_GET_URL + '?groupType=' + data, getConfig())
        .then(rs => rs.data),
    calculatePrice: (data) => axios.post(CALCULATE_PRICE_URL, data, getConfig())
        .then(rs => rs.data),
    getCategories: (data) => axios.get(ALL_CATEGORY_URL, getConfig())
        .then(rs => rs.data),
    getGroup: (data) => axios.get(ALL_GROUP_URL + '?groupType=' + data, getConfig())
        .then(rs => rs.data),
    saveGroup: (data) => axios.post(GROUP_URL, data, getConfig())
        .then(rs => rs.data),
    removeGroup: (id) => axios.delete(GROUP_URL + "/" + id, getConfig())
        .then(rs => rs.data),
    removeCategory: (id) => axios.delete(CATEGORY_URL + "/" + id, getConfig())
        .then(rs => rs.data),
    saveCategory: (data) => axios.post(CATEGORY_URL, data, getConfig())
        .then(rs => rs.data)
};

function getConfig() {
    let token = getToken();
    if (token) {
        return {headers: {'token': token}}
    } else {
        return {}
    }
}

export function getToken() {
    let token;
    if (isClientSide()) {
        let href = document.location.href;
        let index = href.indexOf('key');
        if (index > -1) {
            token = href.substring(index + 4).replaceAll('#', '').replaceAll('tariffs', '')
        }
    }
    return token;
}

export function isClientSide() {
    return typeof window !== 'undefined' && typeof document !== "undefined";
}

let request = obj => {
    return new Promise((resolve, reject) => {
        let xhr = new XMLHttpRequest();
        xhr.timeout = 5000;
        xhr.open(obj.method || "GET", obj.url);
        if (obj.headers) {
            Object.keys(obj.headers).forEach(key => {
                xhr.setRequestHeader(key, obj.headers[key]);
            });
        }
        let token = getToken();
        if (token) {
            xhr.setRequestHeader("token", token);
        }
        xhr.onload = () => {
            if (xhr.status === 401) {
                window.location.href = '/login';
                localStorage.clear()
            }

            if (xhr.status >= 200 && xhr.status < 300) {
                if (obj.url.indexOf("/auth") !== -1 && obj.url.indexOf(AUTHORIZED_GET_URL) === -1) {
                    window.location.href = '/';
                    resolve()
                }
                (obj && obj.method && obj.method === 'DELETE') ? resolve() :
                    (obj.method && (obj.method === 'POST' || obj.method === 'PUT')) ? resolve((xhr.response && xhr.response !== "") ? {body: JSON.parse(xhr.response)} : {}) :
                        !!obj.notJson ? resolve(xhr.response) : resolve(JSON.parse(xhr.response));
            } else if (xhr.status === 500) {
                reject(xhr.response)
            } else {
                reject(JSON.parse(xhr.response));
            }
        };
        xhr.onerror = () => reject(xhr.statusText);
        xhr.send(JSON.stringify(obj.body));
    });
};


let rqFile = (url, file) => {
    return new Promise((resolve, reject) => {
        var xhr = new XMLHttpRequest();
        xhr.file = file; // not necessary if you create scopes like this
        xhr.addEventListener('progress', function (e) {
            // var done = e.position || e.loaded, total = e.totalSize || e.total;
            // console.log('xhr progress: ' + (Math.floor(done / total * 1000) / 10) + '%');
        }, false);

        if (xhr.upload) {
            xhr.upload.onprogress = function (e) {
                // var done = e.position || e.loaded, total = e.totalSize || e.total;
                // console.log('xhr.upload progress: ' + done + ' / ' + total + ' = ' + (Math.floor(done / total * 1000) / 10) + '%');
            };
        }
        xhr.onreadystatechange = function (e) {
            if (4 === this.readyState) {
                // console.log(['xhr upload complete', e]);
            }
        };
        xhr.onload = () => {
            if (xhr.status >= 200 && xhr.status < 300) {
                resolve(xhr.response);
            } else {
                reject(xhr.statusText);
            }
        };
        xhr.open('post', url, true);
        let token = getToken();
        if (token) {
            xhr.setRequestHeader("token", token);
        }
        // xhr.setRequestHeader("Content-Type", "multipart/form-data");
        xhr.send(file);
    });
};

const requests = {
    del: url =>
        request({url: `${API_ROOT}${url}`}).then(responseBody),
    get: (url, notJson) =>
        request({url: `${API_ROOT}${url}`, notJson: notJson})
            .then((responseBody) => {
                return responseBody;
            }).catch(er => {
            console.info(er);
            throw er
        }),
    put: (url, body) =>
        request({
            method: "PUT",
            url: `${API_ROOT}${url}`,
            body: body,
            headers: {"content-type": 'application/json'}
        }).then(responseBody),
    post: (url, body) =>
        request({
            method: "POST",
            url: `${API_ROOT}${url}`,
            body: body,
            headers: {'content-type': 'application/json'}
        }).then(responseBody),
    delete: (url) =>
        request({
            method: "DELETE",
            url: `${API_ROOT}${url}`
        }).then(() => {
        }),
    file: (url, body) =>
        rqFile(`${API_ROOT}${url}`, body)
};

const Auth = {
    login: (login, password) =>
        axios.post(AUTH_URL, {login, password})
            .then(rs => {
                if (isClientSide()) {
                    window.location.href = `/?key=${rs.headers.token}`
                }
            }),
    hasUser: () => {
        return localStorage.getItem('jwtx')
    },
    logout: () => {
        localStorage.clear();
    }
};

const Project = {
    save: (body) => requests.post('/setting/project', body),
    update: (body) => requests.put('/setting/project', body),
    saveTags: (body) => requests.put('/setting/project/tag', body),
    getAll: () => requests.get('/getAllProject'),
    getTags: () => requests.get('/tags'),
    changeOrder: (data) => requests.post('/setting/updateIndex', data),
};

const Price = {
    save: (body) => requests.post('/setting/price', body),
    getAll: () => requests.get('/getPrices'),
    delete: (id) => requests.delete(`/setting/price/${id}`)
};
const News = {
    get: (id) => requests.get(`/news/${id}`, id),
    saveNew: (body) => requests.post('/setting/news', body),
    published: (id) => requests.post(`/setting/news/published/${id}`, null),
    unpublished: (id) => requests.post(`/setting/news/unpublished/${id}`, null),
    update: (id, body) => requests.put(`/setting/news/${id}`, body),
    getAllAdmin: () => requests.get('/setting/news'),
    getAllClient: () => requests.get('/news'),
    delete: (id) => requests.delete(`/setting/news/${id}`)
};
const Order = {
    send: (data) => requests.post('/order', data)
};


const Image = {
    get: id =>
        requests.get(`/image/${id}`, true),
    save: (data, single) =>
        requests.file(`/setting/file?single=` + !!single, data),
    delete: guid =>
        requests.delete(`/setting/file/${guid}`),
};

const Settings = {
    all: page =>
        requests.get(`/setting/getSettings`),
    save: data =>
        requests.post(`/setting/saveSetting`, data),
    getByKey: data =>
        requests.get(`/getSettingsByKeys?${data}`)
}


const User = {
    authorized: () => requests.get(AUTHORIZED_GET_URL)
}

export default {
    Auth,
    Settings,
    Image,
    News,
    Price,
    Order,
    Project,
    User
};
