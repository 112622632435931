import React from 'react';
import agent, {Tariffs} from "../../../agent";
import {i18n} from "../../../localization";
import Button from 'react-bootstrap/Button';
import {imageHandler} from "../../../functions/imageHandler";
import {getImgUrl, renderTariffSettingLink} from "../../../functions";
import Multiselect from 'multiselect-react-dropdown';

class TariffCalculatorSetting extends React.Component {

    constructor(props) {
        super();
        if (props.staticContext) {
            this.state = {
                isLoading: false,
                title: props.staticContext.title,
                description: props.staticContext.body,
                data: props.staticContext.pageData,
                currentGroup: {groupType: 'TARIFF'},
                currentCategory: {},
                groups: [],
                categories: []
            };
        } else {
            this.state = {
                isLoading: true,
                currentCategory: {},
                currentGroup: {},
                groups: [],
                categories: []
            }

        }
    }

    // получение данных
    static fetchData() {
        return Tariffs.getGroup('TARIFF')
            .then(function (rs) { return Tariffs.getCategories()
                .then(function (response) {
                    let groupsId = (rs || []).filter(g => g.groupType === 'TARIFF').map(g => g.id);
                    return {
                        title: i18n.documentTitles.rootPageName,
                        body: "",
                        pageData: rs,
                        category: response.filter(c => groupsId.indexOf(c.group) !== -1)
                    };
                })})

    }

    componentDidMount() {
        if (this.state.isLoading) {
            TariffCalculatorSetting.fetchData().then(data => {
                document.title = i18n.documentTitles.rootPageName;
                this.setState({
                    isLoading: false,
                    title: i18n.documentTitles.rootPageName,
                    description: data.body,
                    groups: data.pageData || [],
                    currentGroup: {},
                    currentCategory: {},
                    categories: data.category|| []
                });
            });
        }
    }


    saveGroup = () => {
        let {name, number, id, image} = this.state.currentGroup;
        let groups = this.state.groups.filter(g => g.id !== id);
        if (!name || !number || !image) {
            this.setState({groupError: true, serverError: undefined})
        } else {
            Tariffs.saveGroup({...this.state.currentGroup, groupType: 'TARIFF'})
                .then(rs => {
                    groups.push({...rs});
                    this.setState({
                        groups: groups,
                        groupError: false,
                        serverError: undefined,
                        currentGroup: {name: "", number: "", description: ""}
                    })
                }).catch(er => {
                this.setState({serverError: "При сохранении произошла ошибка"})
            })
        }
    }

    removeGroup = () => {
        let {id} = this.state.currentGroup;
        let groupId = id;
        let groups = this.state.groups.filter(g => g.id !== id);
        if (this.state.categories.find(c => c.group === groupId)) {
            this.setState({groupError: false, serverError: "Перед удалением группы необходимо удалить категрии которые относятся к данной группе"})
        } else {
            this.deleteImg();
            Tariffs.removeGroup(this.state.currentGroup.id)
                .then(rs => {
                    this.setState({
                        groups: groups,
                        groupError: false,
                        serverError: undefined,
                        currentGroup: {name: "", number: "", description: ""}
                    })
                }).catch(er => {
                this.setState({serverError: "При сохранении произошла ошибка"})
            })
        }
    }

    removeCategory = () => {
        let {id} = this.state.currentCategory;
        let categories = this.state.categories.filter(g => g.id !== id);
            Tariffs.removeCategory(this.state.currentCategory.id)
                .then(rs => {
                    this.setState({
                        categories: categories,
                        categoryError: false,
                        serverCategoryError: undefined,
                        currentCategory: {group: undefined, name: "", number: "", price: "", tooltip: "", descriptions: "", special: false}
                    })
                }).catch(er => {
                this.setState({serverError: "При сохранении произошла ошибка"})
            })

    }

    saveCategory = () => {
        let {group, name, number, price, id} = this.state.currentCategory;
        let categories = this.state.categories.filter(g => g.id !== id);
        if (!name || !number || !group || group.length === 0 || !price) {
            this.setState({categoryError: true, serverCategoryError: undefined})
        } else {
            Tariffs.saveCategory(
                {...this.state.currentCategory,
                    group: group[0].id,
                    requiredTariff: this.state.currentCategory.requiredTariff && this.state.currentCategory.requiredTariff.map(c => c.id),
                    requiredGroups: this.state.currentCategory.requiredGroups && this.state.currentCategory.requiredGroups.map(c => c.id),
                })
                .then(rs => {
                    categories.push({...rs});
                    this.setState({
                        categories: categories,
                        categoryError: false,
                        serverCategoryError: undefined,
                        currentCategory: {group: undefined, name: "", number: "", price: "", tooltip: "", descriptions: "", special: false}
                    })
                }).catch(er => {
                this.setState({serverCategoryError: "При сохранении произошла ошибка"})
            })
        }
    }

    renderGroupInput = (field, name, type) => {
        return <input name="name" type={type} className={'input'}
                      style={{color: 'black', height: '35px'}}
                      value={this.state.currentGroup[field]}
                      onChange={(e) => this.setStateValueFromInput('currentGroup', field, e.target.value)}
                      placeholder={name}/>
    }

    renderCategoryInput = (field, name, type) => {
        return <input name="name" type={type} className={'input'}
                      style={{color: 'black', height: '35px'}}
                      value={this.state.currentCategory[field]}
                      onChange={(e) => this.setStateValueFromInput('currentCategory', field, e.target.value)}
                      placeholder={name}/>
    }

    setStateValueFromInput = (obj, field, value) => {
        this.setState({
            [obj]: {
                ...this.state[obj],
                [field]: value
            }
        })
    }

    deleteImg = () => {
        let img = this.state.currentGroup.image;
        agent.Image.delete(img)
            .then((rs) => {
                this.setState({
                    currentGroup: {
                        ...this.state.currentGroup,
                        image: null
                    }
                })
            })
            .catch(()=>{
                this.setState({
                    currentGroup: {
                        ...this.state.currentGroup,
                        image: null
                    }
                })
            })
    };

    renderCalculator = () => {
        let link = "#";
        return <div>
            <h1>Группы</h1>
            <hr/>
            <div className={'group-list'}>
                {this.state.groups && this.state.groups.sort((a, b) => a.number - b.number)
                    .map(g => {
                        return <div>{g.number}. <a href={link}
                                                   onClick={() => this.setState({
                                                       currentGroup: g,
                                                       groupError: false
                                                   })}>{g.name}</a></div>
                    })}
            </div>
            <div className={'group-setting'}>
                <div>
                    {this.state.groupError && <div className={'error'}>
                        Без заполненного наименования, порядка отображения и изображения сохранение не доступно
                    </div>}
                    {this.state.serverError && <div className={'error'}>{this.state.serverError}</div>}
                    {this.renderGroupInput('name', 'Наименование группы')}
                    {this.renderGroupInput('number', 'Порядок в котором отображается', 'number')}
                    {/*<textarea name="userValue" className={'input'}*/}
                    {/*          style={{color: 'black', minHeight: '100px'}}*/}
                    {/*          value={this.state.currentGroup.description}*/}
                    {/*          onChange={(e) => this.setStateValueFromInput('currentGroup', 'description', e.target.value)}*/}
                    {/*          placeholder="Описание группы"/>*/}
                    {!this.state.currentGroup.image &&
                    <Button onClick={() => imageHandler(img => this.setStateValueFromInput('currentGroup', 'image', img))}
                            variant="outline-warning">
                        Добавить изображение группы
                    </Button>}
                    {this.state.currentGroup.image && <div><img alt={""} src={getImgUrl(this.state.currentGroup.image)} width="150px"/>
                        <Button onClick={this.deleteImg} variant="outline-warning">
                            Удалить изображение группы
                        </Button>
                    </div>}
                    <hr/>
                    <Button onClick={this.saveGroup} variant="outline-warning">
                        Сохранить группу
                    </Button>
                    {this.state.currentGroup.id && <Button onClick={this.removeGroup} variant="outline-warning">
                        Удалить группу
                    </Button>}
                </div>
            </div>
            <hr/>
            <h1>Категории</h1>
            <div id={'#tariffs'}/>
            <hr/>
            <div className={'group-list'}>
                {this.state.groups && this.state.groups.sort((a, b) => a.number - b.number)
                    .map(g => {
                        return <div>{g.number}. {g.name}
                            {this.state.categories && this.state.categories
                                .filter(c => c.group === g.id)
                                .sort((a, b) => a.number - b.number)
                                .map(c => {
                                    return <div>    {g.number}.{c.number} ({c.price}) <a href={'#tariffs'}
                                                                             onClick={() => this.setState(
                                                                                 {currentCategory:
                                                                                         {...c,
                                                                                         group: [g],
                                                                                             requiredTariff: this.state.categories.filter(cat => c.requiredTariff && c.requiredTariff.indexOf(cat.id.toString()) !== -1),
                                                                                             requiredGroups: this.state.groups.filter(cat => c.requiredGroups && c.requiredGroups.indexOf(cat.id.toString()) !== -1)
                                                                                     },
                                                                                     categoryError: false}
                                                                                 )}>{c.name}</a> </div>
                                })
                            }
                        </div>
                    })}
            </div>
            <div className={'group-setting'}>
                <div>
                    {this.state.categoryError && <div className={'error'}>
                        Необходимо заполить группу, наименование, порядок и цену за метр
                    </div>}
                    {this.state.serverCategoryError && <div className={'error'}>{this.state.serverCategoryError}</div>}
                    <Multiselect
                        singleSelect={true}
                        selectedValues={this.state.currentCategory.group}
                        onSelect={this.onSelectGroup}
                        onRemove={this.onRemoveGroup}
                        displayValue="name"
                        placeholder={"Группа"}
                        options={this.state.groups}
                    />
                    {this.renderCategoryInput('name', 'Наименование категории')}
                    {this.renderCategoryInput('number', 'Порядок в котором отображается', 'number')}
                    {this.renderCategoryInput('price', 'Цена за метр квадратный', 'number')}
                    <label className={" clc-checkbox"}><span style={{fontSize: '14px'}}>Цена не умножается на площадь (одна для всего проекта или за штуку)</span>
                        <input type="checkbox" checked={this.state.currentCategory.single}
                               onChange={(e) => this.setStateValueFromInput('currentCategory', 'single', !this.state.currentCategory.single)}/>
                        <span className="checkmark"></span>
                    </label>
                    {this.state.currentCategory.single && this.renderCategoryInput('singleDescription', 'Текст для учточнения цены')}
                    <textarea name="userValue" className={'input'}
                              style={{color: 'black', minHeight: '100px'}}
                              value={this.state.currentCategory.descriptions}
                              onChange={(e) => this.setStateValueFromInput('currentCategory', 'descriptions', e.target.value)}
                              placeholder="Перечень входящего в группу"/>
                    {this.renderCategoryInput('tooltip', 'Всплывающий текст')}
                    <label className={" clc-checkbox"}><span style={{fontSize: '14px'}}>Будет добавлена звездочка, не будет участвовать в рассчете если выбран другая категория группы</span>
                        <input type="checkbox" checked={this.state.currentCategory.special}
                               onChange={(e) => this.setStateValueFromInput('currentCategory', 'special', !this.state.currentCategory.special)}/>
                        <span className="checkmark"></span>
                    </label>
                    <Multiselect
                        showCheckbox={true}
                        selectedValues={this.state.currentCategory.requiredGroups}
                        onSelect={this.onSelectRequiredGroup}
                        onRemove={this.onSelectRequiredGroup}
                        displayValue="name"
                        placeholder={"Обязательные группы"}
                        options={this.state.groups}
                    />
                    <Multiselect
                        showCheckbox={true}
                        selectedValues={this.state.currentCategory.requiredTariff}
                        onSelect={this.onSelectRequiredCategory}
                        onRemove={this.onSelectRequiredCategory}
                        displayValue="name"
                        placeholder={"Обязательные категории"}
                        options={this.state.categories}
                    />
                    <hr/>
                    <Button onClick={this.saveCategory} variant="outline-warning">
                        Сохранить категорию
                    </Button>
                    {this.state.currentCategory.id && <Button onClick={this.removeCategory} variant="outline-warning">
                        Удалить категорию
                    </Button>
                    }
                </div>
            </div>
            <hr/>
        </div>
    }

    onSelectGroup = (selectedList, selectedItem) => {
        this.setState({
            currentCategory: {
                ...this.state.currentCategory,
                group: [selectedItem]
            }
        })
    }
    onSelectRequiredGroup = (selectedList, selectedItem) => {
        this.setState({
            currentCategory: {
                ...this.state.currentCategory,
                requiredGroups: selectedList
            }
        })
    }
    onSelectRequiredCategory = (selectedList, selectedItem) => {
        this.setState({
            currentCategory: {
                ...this.state.currentCategory,
                requiredTariff: selectedList
            }
        })
    }

    onRemoveGroup = (selectedList, removedItem) => {
        this.setState({
            currentCategory: {
                ...this.state.currentCategory,
                group: []
            }
        })
    }
    onSelect = (selectedList, selectedItem) => {

    }

    onRemove = (selectedList, removedItem) => {

    }

    render() {
        return <div>
            {renderTariffSettingLink()}
            {this.renderCalculator()}
            <div className={'mp-btn'}>
                <Button label={"Получить"} onClick={() => this.calculate()}/>
            </div>
        </div>;
    }

}

export default TariffCalculatorSetting;
