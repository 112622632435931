import React from "react";
import {i18n} from "../../../../localization";
import Tariff from "./Tariff";
import AnimatedOnView from "../../AnimatedOnView";

class TariffBlock extends React.Component {
    render() {
        /*const {tariffInfo} = this.props.data;*/
        return <div className={'mp-tariff-block'}>
            <AnimatedOnView id="tariffTitle">
                <div className={'tariff-title'}><h1>{i18n.mainPage.tariffBlock.title}</h1></div>
            </AnimatedOnView>
            <Tariff cst={true}
                    id={1}
                    price={i18n.mainPage.tariffBlock.constructorPrice}
                    title={i18n.mainPage.tariffBlock.constructorTitle}
                    info={i18n.mainPage.tariffBlock.constructorOption}/>
            {/*<Tariff cst={false}*/}
            {/*        id={2}*/}
            {/*        price={""}*/}
            {/*        title={"пройди тест"}*/}
            {/*        info={"Мы хотим помочь Вам определиться с подходящим для вас пакетом услуг. Ответьте на 5 вопросов, чтобы мы помогли Вам сделать наилучший выбор."}*/}
            {/*/>*/}
        </div>
    }
}

export default TariffBlock;