import React from "react";
import './button.css'

class Button extends React.Component {

    render() {
        return <a className={this.props.small ? 'cm-btn-small cm-btn' : 'cm-btn'}
                  href={this.props.link} onClick={() => this.props.onClick && this.props.onClick()}>
            <div className={'cm-btn-animation'}>
                <div className={'cm-btn_effect'}></div>
            </div>
            <div className={this.props.small ? 'cm-btn-text-small cm-btn-text' : 'cm-btn-text'}>{this.props.label}</div>
        </a>
    }
}

export default Button