import React from "react";
import {addVisibleListener} from "../../functions";
import '../../styles.css'


class AnimatedOnView extends React.Component {

    state = {};

    componentDidMount() {
        addVisibleListener(this.props.id, this.state, this.updateVisible, this.props.link);
        let deffClass = this.props.deffClass;
        let animatedClass = this.props.animatedClass;
        if (!deffClass) {
            deffClass = "";
        }
        if (!animatedClass) {
            animatedClass = "animation-on-view";
        }
        this.setState({
            deffClass: deffClass,
            animatedClass: deffClass + " " + animatedClass
        })
    }

    updateVisible = (value) => {
        this.setState({[this.props.id + 'Visible']: value})
    };

    render() {
        if (this.props.link) {
            return <a href={this.props.href}
                      id={this.props.id}
                      className={`${this.state[this.props.id + 'Visible'] ? this.state.animatedClass : this.state.deffClass}`}>
                {this.props.children}
            </a>
        }
        return <div id={this.props.id}
                    className={`${this.state[this.props.id + 'Visible'] ? this.state.animatedClass : this.state.deffClass}`}>{this.props.children}</div>
    }
}

export default AnimatedOnView;