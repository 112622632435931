import React from "react";
import './main-page.css';

import FirstBlok from "./first-block/FirstBlok";
import PortfolioBlock from "./portfolio/PortfolioBlock";
import TariffBlock from "./tariff/TariffBlock";
import LifeSickle from "./lifecicle/LifeCicle";
import Footer from "../footer/Footer";
import {getImgUrl, updateDescription, updateTitle} from "../../../functions";
import {i18n} from "../../../localization";
import {TARIFF_ID} from "../../../constants";

const data = {
    portfolioIMG: [
        getImgUrl('871984e3-f857-4ab8-b2b5-7ab52f3aef93.jpg'),
        getImgUrl('7850bf5b-693c-480b-9199-ccd3ca0566be.jpg'),
        getImgUrl('085bbc06-94f0-46bf-9da6-19b6cc491dc6.jpg'),
        getImgUrl('e5ad1f95-3d25-4308-94d4-e670d70c856c.jpg'),
        getImgUrl('6cdfcb6c-5526-4524-ae16-dfd1b965ca3c.jpg'),
        getImgUrl('0a807a94-5b9f-4341-bb73-579f9b87d878.jpg')
    ],
    tariffInfo: [{
        title: 'Планировка',
        price: '500₽/м²',
        info: '2 варианта планировочных решений План демонтажа ненесущих конструкций План монтажа Итоговый вариант планировочного решения с расстановкой мебели'
    }, {
        title: 'Базовый',
        price: '1000₽/м²',
        info: '2 варианта планировочных решений Итоговый вариант планировочного решения с расстановкой мебели Стилевое решение/мудборд Рабочая документация'
    }, {
        title: 'Стандарт',
        price: '1500₽/м²',
        info: '2 варианта планировочных решений Итоговый вариант планировочного решения с расстановкой мебели 3D-визуализация всех помещений Рабочая документация'
    }, {
        title: 'Премиум',
        price: '2000₽/м²',
        info: '2 варианта планировочных решений Итоговый вариант планировочного решения с расстановкой мебели 3D-визуализация всех помещений с розетками и выключателями Рабочая документация Спецификации'
    }]
};


class Main extends React.Component {

    state={
        pageData: data
    };

    componentDidMount() {
        updateTitle(i18n.documentTitles.mainTitle);
        updateDescription(i18n.documentTitles.mainDescription);
    }

    render() {
        return <div>
            <FirstBlok data={this.state.pageData}/>
            <PortfolioBlock data={this.state.pageData}/>
            <div className={"clear"}/>
            <div id={TARIFF_ID} className={"tariff-id-link"}/>
            <TariffBlock data={this.state.pageData}/>
            <div className={"clear"}/>
            <LifeSickle/>
            <div className="clear"></div>
            <Footer/>
        </div>
    }
}

export default Main