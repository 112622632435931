import React from 'react';
import Container from 'react-bootstrap/Container'
import {getPath} from "../../../functions";
import {MAIN_PATH} from "../../../constants";

class PageNotFound extends React.Component {
    state = {};



    render() {

        return <div style={{height: '100vh'}}>
            <Container>
                <div style={{fontSize: '100px', textAlign: "center", paddingTop: "20vh"}}>404</div>
                <h1  style={{fontSize: '50px', textAlign: "center"}}>Страница не найдена</h1>
                <div style={{fontSize: '20px', textAlign: "center"}}> <a  href={getPath(MAIN_PATH)}>На Главную</a></div>

            </Container>
        </div>;
    }
}

export default PageNotFound;
