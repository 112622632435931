import React from "react";
import './footer.css'
import {i18n} from "../../../localization";
import SocialLink from "../SocialLink";
import Button from "../Button/Button";
import {MaskedInput, createDefaultMaskGenerator} from 'react-hook-mask';
import {CONSENT_TO_PROCESSING_PERSONAL_DATA_LINK, PRIVACY_POLICY_LINK, Tariffs} from "../../../agent";

const maskGenerator = createDefaultMaskGenerator('+7 (999) 999-99-99');


class Footer extends React.Component {

    state = {};

    render() {
        return <div className={'footer'}>
            {!this.props.isContact && <h1>{i18n.footer.title}</h1>}
            {this.props.isContact && <h1 style={{paddingBottom: "80px"}}>{i18n.contacts.title}</h1>}
            {!this.props.isContact && <div className={'footer-info'}>{i18n.footer.info}</div>}
            <div className={'f-inline f-top'}>
                <div className={'f-contact-info'}>{i18n.company.phone}</div>
                <div className={'f-contact-info'}>{i18n.company.host}</div>
                <div className={'f-contact-info'}><SocialLink/></div>
                <div className={'f-contact-info privacy-policy'}>
                    <a href={PRIVACY_POLICY_LINK} target={"_blank"}>Политика конфиденциальности</a>
                </div>
            </div>
            <div className={'f-inline'}>
                <div className={'f-form'}>
                    <form id={'contactForm'}>
                        {this.state.requestSended && <div>{i18n.footer.requestSended}</div>}
                        <div>
                            <input type="text" name="userName" size="40" className={'input'}
                                   onChange={(e) => this.setState({name: e.target.value, nameError: false})}
                                   aria-required="true" aria-invalid="false"
                                   placeholder="Имя"/>
                            {this.state.nameError && <div className={"error"}>{i18n.error.nameRequired}</div>}
                        </div>
                        <div className={"phone-input"}>
                            <MaskedInput
                                placeholder="+7 (999) 999-99-99"
                                maskGenerator={maskGenerator}
                                value={this.state.value}
                                onChange={(phone) => this.setState({phone: phone, phoneError: false})}
                            />
                            {this.state.phoneError && <div className={"error"}>{i18n.error.phoneError}</div>}
                        </div>
                        <div>
                    <textarea className={'input input-textarea'}
                              onChange={(e) => this.setState({order: e.target.value})}
                              id="story" name="story" placeholder="Сообщение"
                              rows="7" cols="33"/>
                        </div>
                        <div style={{paddingBottom: '20px'}}>
                        <div style={{fontSize: '15px'}} className={'personal-data-style'}>
                            Нажимая на кнопку, я соглашаюсь на&nbsp;
                            <a  href={CONSENT_TO_PROCESSING_PERSONAL_DATA_LINK}
                               target={"_blank"}>обработку персональных данных</a>.
                        </div>
                        </div>
                        <div>
                            <div className={'text-center ft-btn'}><Button
                                label={this.props.isContact ? i18n.contacts.contactBtn : i18n.footer.calculate}
                                onClick={() => this.sendRequest()}/></div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    }

    sendRequest = () => {
        if (!this.state.phone || !this.state.name || this.state.phone.length < 10) {
            this.setState({nameError: !this.state.name, phoneError: !this.state.phone || this.state.phone.length < 10});
            return;
        }
        let data = {
            phone: this.state.phone,
            name: this.state.name,
            orderText: this.state.order,
            order: true
        };
        Tariffs.calculatePrice(data)
            .then(rs => {
                this.setState({requestSended: true})
            })
    }
}

export default Footer;