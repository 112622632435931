import React from 'react';
import {history, store} from "../store";
import {ConnectedRouter} from "react-router-redux";
import {Route, Switch} from "react-router-dom";
import App from "./App";
import {Provider} from "react-redux";

// экспорт главного компонента приложения
export class Start extends React.Component {

    // рендер представления
    render() {

        return <Provider store={store}>
            <ConnectedRouter history={history}>
                <Switch>
                    <Route path="/" component={App} />
                </Switch>
            </ConnectedRouter>
        </Provider>;
    }
}

export default Start