import React from "react";
import {getToken, IMG_URL, isClientSide} from "./agent";
import {i18n} from "./localization";
import {
    ADD_NEW_PATH, CATEGORY_LABEL,
    CHANGE_ORDER_PATH,
    MAIN_PATH, PORTFOLIO_PATH,
    PORTFOLIO_SETTING_PATH, PROJECT_LABEL,
    SINGLE_IMAGE_SETTING_PATH, TARIFF_SETTING_PATH, TEST_SETTING_PATH
} from "./constants";

export const getImgUrl = (guid) => IMG_URL + guid;

export const closeBtn = (onclick, className, red = false) => <svg xmlns="http://www.w3.org/2000/svg"
                                                                  className={className}
                                                                  width="18"
                                                                  height="18"
                                                                  fill="red"
                                                                  viewBox="0 0 19 19"
                                                                  onClick={(arg) => onclick(arg)}>
    <path className="close-menu-burger "
          stroke={red ? "#f8f7ff" : "#000000"}
          style={{fill: red ? "#fdffff" : "#000000"}}
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1.9" d="M9 9.5l8-8-8 8-8-8 8 8zm0 0l8 8-8-8-8 8 8-8z">
    </path>
</svg>


export function getPath(path) {return  getToken() ? `${path}?key=${getToken()}` : path};

export function isActiveMenu(path) {
      if (isClientSide()) {
          if (path === MAIN_PATH) {
              return (document.location.href.lastIndexOf(path) + 1) === document.location.href.length;
          }
          if (path === PORTFOLIO_PATH) {
              let href = document.location.href;
              return href.indexOf(path) > -1 || href.indexOf(PROJECT_LABEL) > -1 || href.indexOf(CATEGORY_LABEL) > -1 ;
          }
          let href = document.location.href;
          return href.indexOf(path) > -1;
      } else  {
          return false;
      }
};

export function updateTitle(title) {
    if (isClientSide() && document) {
        document.title = title;
    }
}

export function updateDescription(description) {
    if (isClientSide() && document) {
        document.getElementsByTagName('meta')["description"].content = description;
    }
}

export function addVisibleListener(elementId, state, updateCb, isPart) {
    if (isClientSide()) {
        window.addEventListener('scroll', (event) => {
            let elementById = document.getElementById(elementId);
            if (elementById) {
                let visible = checkVisible(elementById, isPart);
                if ((!state[elementId + 'Visible'] && visible) || isPart) {
                    updateCb && updateCb(visible)
                }
            }
        })
    }
}

export function checkVisible(elm, isPart) {
    var rect = elm.getBoundingClientRect();
    var viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
    if (isPart) {
        var viewWidth = Math.max(document.documentElement.clientWidth, window.innerWidth);
        let shift = (viewHeight - viewWidth) ;
        return !(rect.bottom - shift < 0 || rect.top + shift - viewHeight >= 0);
    }
    return !(rect.bottom < 0 || rect.top - viewHeight >= 0);
}

export function renderAdminLink() {
    return <div>
        <div className={'inline-block admin-link'}>
            <a href={getPath(CHANGE_ORDER_PATH)} >{i18n.portfolio.changeOrderLabel}</a>
        </div>
        <div  className={'inline-block admin-link'}>
            <a href={getPath(ADD_NEW_PATH)}>{i18n.portfolio.addProjectLabel}</a>
        </div>
        <div  className={'inline-block admin-link'}>
            <a href={getPath(PORTFOLIO_SETTING_PATH)}>{i18n.portfolio.categorySetting}</a>
        </div>
        <div  className={'inline-block admin-link'}>
            <a href={getPath(SINGLE_IMAGE_SETTING_PATH)}>{i18n.portfolio.singleImageSetting}</a>
        </div>
    </div>
};

export function renderTariffSettingLink() {
    return <div>
        <div className={'inline-block admin-link'}>
            <a href={getPath(TARIFF_SETTING_PATH)} >{i18n.calculator.calculatorSettingPageLink}</a>
        </div>
        <div  className={'inline-block admin-link'}>
            <a href={getPath(TEST_SETTING_PATH)}>{i18n.calculator.testSettingPageLink}</a>
        </div>
    </div>
};