import React from 'react';

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import agent from "../agent";
import Button from 'react-bootstrap/Button';
import IconButton from '@material-ui/core/IconButton';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container'

class Prices extends React.Component {
    state = {};

    componentDidMount() {
        document.title = "Услуги и цены MY DREAMS";
        this.getData();
        window.addEventListener('resize',
            () => this.setState({
                height: window.innerHeight,
            }))
    }

    getData = () => {
        agent.Price.getAll()
            .then(rs => {
                this.setState({data: rs.list})
            });
    };

    onEditorStateChange = (editorState) => {
        this.setState({
            editorState
        });
    };

    saveNew = () => {
        let {description} = this.state;
        if (description) {
            agent.Price.save({
                id: this.state.editId,
                label: 'ПРОЕКТ',
                name: this.state.name,
                price: this.state.price,
                orderNumber: this.state.orderNumber,
                description: description
            })
                .then(rs => {
                    this.setState({
                        addNew: false,
                        editId: undefined,
                        name: undefined,
                        price: undefined
                    });
                    this.getData()
                })
        }
    };

    delete = (i, index) => {
        let {data} = this.state;
        agent.Price.delete(i.id)
            .then(() => {
                data.splice(index, 1);
                this.setState({data})
            })
    };

    // onEdit = (i) => {
    //     this.state.description = i.description;
    //     this.setState({editId: i.id, addNew: true, name: i.name, orderNumber: i.orderNumber, price: i.price})
    // };

    render() {
        let isAdmin = this.props.admin;
        let {addNew} = this.state;
        return (
            <div>
                <div className={'padding'}/>
                {isAdmin  && !addNew && <Button onClick={() => this.setState({addNew: true, description: " "})} variant="dark">Добавить</Button>}
                {!addNew && <Container id={'priceContainer'}>
                    <div className="services_row">
                        {this.state.data && this.state.data.map((i, index) => {
                            return <div className="services_col" key={index}>
                                <div className="services_item">
                                    <div className="services_top">
                                        <div className="services_heading" itemProp="name">{i.name}</div>
                                        <div className={"se-container"}>
                                            <div className={"se-wrapper"}>
                                                <div className="se-wrapper-inner se-wrapper-wysiwyg sun-editor-editable"
                                                     contentEditable="false">
                                                    <div className={"services_list"}
                                                         dangerouslySetInnerHTML={{__html: i.description}}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="services_bottom"><span itemProp="price">{i.price}</span> ₽/м²</div>
                                    {isAdmin && <div>
                                        {/*<IconButton onClick={() => this.onEdit(i, index)}>*/}
                                        {/*    <CreateIcon color="primary" fontSize="small"/>*/}
                                        {/*</IconButton>*/}
                                        <IconButton aria-label="delete" onClick={() => this.delete(i, index)}>
                                            <DeleteForeverIcon color="secondary" fontSize="large"/>
                                        </IconButton>
                                    </div>}
                                </div>
                            </div>
                        })}
                    </div>
                </Container>
                }

                {isAdmin && addNew && <div style={{
                    height: "150px"
                }}>
                    <Form.Row>
                        <Form.Group as={Col} md="6" controlId="validationCustom01">
                            <Form.Control
                                type="text"
                                placeholder="Наименование"
                                value={this.state.name}
                                onChange={(e) => {
                                    this.setState({name: e.target.value})
                                }}
                                required
                            />
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} md="6" controlId="validationCustom04">
                            <Form.Control
                                type="text"
                                placeholder="Цена"
                                value={this.state.price}
                                onChange={(e) => {
                                    this.setState({price: e.target.value})
                                }}
                                required
                            />
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} md="6" controlId="validationCustom04">
                            <Form.Control
                                type="text"
                                placeholder="Порядок отображения"
                                value={this.state.orderNumber}
                                onChange={(e) => {
                                    this.setState({orderNumber: e.target.value})
                                }}
                                required
                            />
                        </Form.Group>
                    </Form.Row>

                </div>}
                {isAdmin && addNew && <div >

                    {isAdmin && addNew && <Button onClick={this.saveNew} variant="dark">Сохранить</Button>}
                    {isAdmin && addNew && <Button onClick={()=>  this.setState({
                        addNew: false,
                        editId: undefined,
                        name: undefined,
                        description: " ",
                        orderNumber: undefined,
                        price: undefined
                    })} variant="dark">Отмена</Button>}
                </div>}
            </div>
        );
    }

    handleChange = (description) => {
        this.setState({description: description});
    }
}

export default Prices;
