import React from "react";
import {getImgUrl, getPath, isActiveMenu} from "../../functions";
import {CONTACTS_PATH, MAIN_PATH, OLD_TARIFF_PATH, PORTFOLIO_PATH, TARIFF_PATH} from "../../constants";
import {i18n} from "../../localization";
import {isClientSide} from "../../agent";
import DehazeIcon from '@material-ui/icons/Dehaze';
import SocialLink from "./SocialLink";

class Menu extends React.Component {

    state = {
        menu: [
            {path: MAIN_PATH, name: i18n.menu.rootPageName},
            {path: PORTFOLIO_PATH, name: i18n.menu.portfolio},
            {path: TARIFF_PATH, name: i18n.menu.tariff},
            {path: CONTACTS_PATH, name: i18n.menu.contacts}
        ],
        phoneMenu: [
            {path: MAIN_PATH, name: i18n.menu.rootPageName},
            {path: PORTFOLIO_PATH, name: i18n.menu.portfolio},
            {path: OLD_TARIFF_PATH, name: i18n.menu.tariff},
            {path: CONTACTS_PATH, name: i18n.menu.contacts}
        ]
    };

    render() {
        return (
            <div>
                {this.renderFixedMenu()}
                {this.renderModal()}
            </div>)
    }

    renderModal = () => {
        let {animate, menu} = this.state;
        let classMenu = !animate ? "shot-menu" : "long-menu";
        return this.state.showMenu &&<div className={'modal-br'} onClick={this.showMenu}> <div className={classMenu}>
            <div className={'my-modal'}>
                <div className="mobile-header_hidden">
                    <div>
                        <div className="mobile-header_row-2"><a
                            href={i18n.company.whatsappHref}
                            className="mobile-header_whatsapp"> <img
                            src={getImgUrl('web-image/phone-icon.png')}
                            alt="WhatsApp"
                            height="34" width="34"/> </a>
                            <div>
                                <div className="header_tels">
                                    <a href={'tel:' + i18n.company.phone} className="header_tel">
                                        {i18n.company.phone}
                                    </a>
                                    <span className="header_time">{i18n.company.worckTime}</span></div>
                            </div>
                        </div>
                        <div className="">
                            <ul className="header_nav-list __cloned">
                                {menu.map((item, key) =>
                                    <li id={`menu-item-${key}`}
                                        className="menu-item menu-item-type-post_type menu-item-object-page">
                                        <a href={getPath(item.path)}> {item.name}</a>
                                    </li>
                                )}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    }

    renderFixedMenu = () => {
        return <div className={"fixed-menu transform-show2"}>
            <div className={"fixed-top"}>
                {this.renderMenu()}
            </div>
        </div>
    };

    renderMenu = () => {
        let admin = this.props.admin;
        let menu = this.state.menu;
        return <div>
            <div className={"menu-top menu"}>
                <div className={'table-div'}>
                    <div className={"row-div"}>
                        <div className={"cell-div-right size-logo"} >
                            <a href={getPath(MAIN_PATH)} ><img alt={""} src={getImgUrl('web-image/logo_top.png')}/></a>
                        </div>
                        <div className={"cell-div-big"}>
                            {menu.map((item, index) => <a href={getPath(item.path)}
                                                          key={"link-key" + index}
                                                 className={`nav-link ${isActiveMenu(item.path) ? "active" : ""}`}>
                                {item.name}
                            </a>)}
                            {admin && <a href={"/"} className={'nav-link'}>{'ВЫХОД'}</a>}
                        </div>
                        <SocialLink/>
                        <div style={{width: '65%'}} className={"cell-div only-phone"}/>
                        <div className={"only-phone"}>
                            <div className={"mobile-menu"}
                                 onClick={this.showMenu}>
                                <DehazeIcon className={'pointer text-white'}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    };

    showMenu = () => {
        if (!this.state.showMenu) {
            this.setState({showMenu: !this.state.showMenu, animate: false})
            if (isClientSide()) {
                setTimeout(() => this.setState({animate: !this.state.animate}));
                isClientSide() && document.body.classList.add('modal-open');
            }
        } else {
            this.setState({animate: !this.state.animate, showMenu: true})
            if (isClientSide()) {
                setTimeout(() => this.setState({showMenu: !this.state.showMenu}), 1000);
                isClientSide() && document.body.classList.remove('modal-open');
            }
        }
    };
}

export default Menu;