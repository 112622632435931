export const MAIN_PATH = "/";
export const PORTFOLIO_PATH = "/portfolio";
export const TARIFF_PATH = "/prices";
export const OLD_TARIFF_PATH = "/prices-page";
export const TARIFF_SETTING_PATH = "/tariff/setting";
export const TEST_SETTING_PATH = "/test/setting";
export const TEST_PATH = "/serviceSelection";
export const CONTACTS_PATH = "/contacts";
export const PROJECT_LABEL = "/project";
export const PROJECT_PATH = PROJECT_LABEL + "/:guid";
export const CATEGORY_LABEL = "/category";
export const CATEGORY_PATH = CATEGORY_LABEL + "/:id";
export const EDIT_PATH = "/edit/:guid";
export const ADD_NEW_PATH = "/addNew";
export const CHANGE_ORDER_PATH = "/ordering";
export const PORTFOLIO_SETTING_PATH = "/portfolioSetting";
export const SINGLE_IMAGE_SETTING_PATH = "/singleImageSetting";
export const NEWS_LIST_PATH = "/news";
export const NEWS_PATH = "/news/:guid";
export const LOGIN_PATH = "/login";

export const PORTFOLIO_ID = 'portfolio';
export const TARIFF_ID = 'tariff';