import React from 'react';
import Image from 'react-bootstrap/Image'
import {Slide, Fade} from "react-slideshow-image";
import 'react-slideshow-image/dist/styles.css'
import {isClientSide, Projects} from "../../../agent";
import {closeBtn, getImgUrl, getPath, updateDescription, updateTitle} from "../../../functions";
import Button from 'react-bootstrap/Button';
import {i18n} from "../../../localization";
import './project.css';
import {CATEGORY_LABEL, PORTFOLIO_PATH} from "../../../constants";
import Footer from "../footer/Footer";

const properties = {
    transitionDuration: 500,
    autoplay: false
};

const style = (gorizontal) => {
    if (gorizontal) {
        return {
            textAlign: "center",
            padding: "3px 0",
            height: '100vh',
            width: 'auto',
            willChange: "transform"
        }
    } else {
        return {
            textAlign: "center",
            padding: "3px 0",
            height: 'auto',
            width: '100vw',
            willChange: "transform",
            margin: 'auto'
        }
    }
};

class Project extends React.Component {
    state = {
        modal: false,
        isLoading: true,
        height: 1500,
        windowWidth: 1900
    };

    constructor(props) {

        super();

        // component state
        if (props.staticContext) {
            this.state = {
                isLoading: false,
                id: props.staticContext.id,
                guid: props.staticContext.guid,
                name: props.staticContext.name,
                title: props.staticContext.title,
                metaDescription: props.staticContext.metaDescription,
                description: props.staticContext.description,
                data: props.staticContext.pageData,
                planImg: props.staticContext.planImg,
                modal: false,
                height: 1500,
                windowWidth: 1900,
                isCategory: props.staticContext.isCategory,
                isProject: props.staticContext.isProject,
                categories: props.staticContext.categories,
            };
        }
    }

    // получение данных
    static fetchData(url, location) {
        if (location.indexOf(CATEGORY_LABEL) !== -1) {
            return Projects.getImageCategories()
                .then(function (categories) {
                    return Projects.getImageByCategories(url.substring(url.lastIndexOf("/")))
                        .then(function (response) {
                            let title = "";
                            if (response && response.data) {
                                if (response.data.metaTitle) {
                                    title = response.data.metaTitle;
                                } else if (response.data.title) {
                                    title = response.data.title;
                                } else {
                                    title = i18n.documentTitles.rootPageName;
                                }
                            } else {
                                title = i18n.documentTitles.rootPageName;
                            }
                            return {
                                id: response.data ? response.data.id : '',
                                guid: response.data ? response.data.url : '',
                                name: response.data ? response.data.title : '',
                                title: title,
                                metaDescription: response.data ? response.data.metaDescription : '',
                                description: response.data && response.data.description,
                                pageData: response.data && response.data.images,
                                planImg: response.data && response.data.planImg,
                                categories: categories,
                                isCategory: true
                            };
                        })
                })
        } else {
            return Projects.getProjectByGuid(url.substring(url.lastIndexOf("/")))
                .then(function (response) {
                    let title = "";
                    if (response && response.data) {
                        if (response.data.metaTitle) {
                            title = response.data.metaTitle;
                        } else if (response.data.title) {
                            title = response.data.title;
                        } else {
                            title = i18n.documentTitles.rootPageName;
                        }
                    } else {
                        title = i18n.documentTitles.rootPageName;
                    }
                    return {
                        guid: response.data ? response.data.url : '',
                        name: response.data ? response.data.title : '',
                        title: title,
                        metaDescription: response.data ? response.data.metaDescription : '',
                        description: response.data && response.data.description,
                        pageData: response.data && response.data.images,
                        planImg: response.data && response.data.planImg,
                        isProject: true
                    };
                })
        }
    }

    componentDidMount() {
        if (this.state.isLoading) {
            if (typeof document !== "undefined") {
                let href = document.location.href;
                Project.fetchData(href.substring(href.lastIndexOf("/")), href).then(data => {
                    updateTitle(data.title);
                    updateDescription(data.metaDescription);
                    this.setState({
                        isLoading: false,
                        ...data,
                        data: data.pageData
                    });
                });
            }
        }
    }

    onEdit = () => {
        if (isClientSide() && this.state.isProject) {
            window.location.href = getPath('/edit/' + this.state.guid)
        }
    };

    getDescription = () => {
        let {description, showAll} = this.state;
        if (!showAll && description && isClientSide() && description.length > 1500) {
            return description.substring(0, 1500) + '...';
        } else {
            return description;
        }
    }

    renderGallery = () => {
        return this.state.data && this.state.data.map((img, index) => {
            let className = img.gorizontal && !this.state.isCategory ? 'box pointer project-img-long' : 'box pointer project-img-shot';
            let alt = img.tags ? img.tags : this.state.name;
            let key = 'project-data-' + index;
            return <div key={key} className={className}>
                <img src={getImgUrl(img.image)}
                     width={"100%"}
                     alt={alt}
                     title={this.state.name}
                     onClick={() => this.onClickImg(img)}
                />
            </div>
        })
    }

    onClickImg = (img) => {
        isClientSide() && document.body.classList.add('modal-open');
        this.setState({modal: true, modalData: this.getModalData(img)})
    }

    renderBtn = () => {
        const {admin} = this.props;
        return <div>
            {admin && this.state.isProject && <Button onClick={this.onEdit} variant="dark">{i18n.button.edit}</Button>}
        </div>
    }

    renderCategory = () => {
        return this.state.isCategory && <div>
            <div className={"sub-menu no-phone"}>
                {this.state.categories.map(c => <a className={`nav-link ${c.id === this.state.id ? "active" : ""}`}
                                                   href={getPath(CATEGORY_LABEL + "/" + c.id)}>{c.name}</a>)}
            </div>
            <div className={'broadcrambs'}>
                <a href={getPath(PORTFOLIO_PATH)}>{i18n.menu.portfolio}</a>&nbsp;/&nbsp;
                {this.state.categories
                    .filter(c => c.id === this.state.id)
                    .map(c => <a href={getPath(CATEGORY_LABEL + "/" + c.id)}>{c.name}</a>)}

            </div>
        </div>
    }

    renderLogo = () => {
        return <div className={"row-div playfair no-tablet no-phone color-white"}>
            <div className={"pj-cell-div-right"}>
                <img alt={""} src={getImgUrl('media/logo.png')} width={'40%'}/>
            </div>
            <div className={"cell-div-10"}>
                <div className={"row-div"}>
                    <div className={"cell-div-100"}>
                        <div className={'h-15 border-bt'}>
                            {i18n.company.name}
                        </div>
                        <div className={'h-15'}>
                            студия дизайна
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }

    renderDescription = () => {
        let {showAll, descriptionOpen} = this.state;
        let label = showAll ?
            <span className={'open-label auto-size'}>Свернуть<span className={"arr-btn"}/> </span> :
            <span className={'open-label auto-size'}>Развернуть<span className={"arr-top"}/> </span>;
        let description = this.getDescription();
        let mobileOpenBtnClass = !descriptionOpen ? "open-btn" : "close-btn"
        let descriptionHtml = <div className={"se-container"}>
            <div className={"se-wrapper "}>
                <div className={"se-wrapper-inner se-wrapper-wysiwyg sun-editor-editable"}
                     contentEditable="false">
                    {this.renderLogo()}
                    <hr className={'only-desctop'}/>
                    <div className={'description-text auto-size'}
                         dangerouslySetInnerHTML={{__html: this.getDescription()}}/>
                </div>
            </div>
        </div>;

        let button = <div className={" color-white auto-size only-desctop"}>
            {!showAll && <div className={'description-gradient no-phone'}/>}
            <div style={{textAlign: 'right'}}>
                {description && description.length > 1000 && <a onClick={this.changeShowAll}>{label}</a>}
            </div>
            <div className={'auto-size no-phone'} style={{
                textAlign: 'center',
                fontFamily: 'Playfair',
                fontStyle: 'italic'
            }}>{i18n.company.phone}
            </div>
        </div>

        return <div className={"project-description pr-background-fon"}>
            <div className={'no-desctop'}>
                <h4 style={{textAlign: 'center', fontFamily: 'Playfair'}}><b>{this.state.name}</b></h4>
                <div className={"mobile-description"} onClick={this.changeShowAllMobile}>
                    <div>{i18n.portfolio.description}</div>
                    <img alt={""} className={mobileOpenBtnClass} src={getImgUrl("media/open.svg")}/>
                </div>
            </div>
            <div className={"description-height"}>
                {descriptionHtml}
                {showAll && button}
            </div>
            {!showAll && <div className={'description-btn'}>
                {button}
            </div>}

        </div>
    }

    changeShowAll = () => {
        this.setState({showAll: !this.state.showAll})
    }
    changeShowAllMobile = () => {
        this.setState({showAll: !this.state.showAll, descriptionOpen: !this.state.descriptionOpen})
    }
    changeShowPlan = () => {
        this.setState({planClosed: !this.state.planClosed})
    }
    changeShowGallery = () => {
        this.setState({galleryClosed: !this.state.galleryClosed})
    }

    planImage = () => {
        return !this.state.planClosed && <div className={"plan-img"}>{this.state.planImg &&
        <img alt={""} src={getImgUrl(this.state.planImg)}
             title={this.state.name}/>}
        </div>
    };

    render() {
        let {showAll, planClosed, galleryClosed} = this.state;
        let mobileOpenBtnClass = planClosed ? "open-btn" : "close-btn"
        let mobileGalleryClass = galleryClosed ? "open-btn" : "close-btn"
        let className = showAll ? ' show-all' : ' show-small';
        return <div className={'playfair'}>
            {this.renderBtn()}
            {this.renderCategory()}
            {this.state.isProject && <div className={className}>
                {this.renderDescription()}
                <div className={"mobile-description"} onClick={this.changeShowPlan}>
                    <div>{i18n.portfolio.plan}</div>
                    <img alt={""} className={mobileOpenBtnClass} src={getImgUrl("media/open.svg")}/>
                </div>
                {this.planImage()}
            </div>}
            {!this.state.isCategory && <div className={'no-desctop'} onClick={this.changeShowGallery}>
                <div className={"mobile-description "}>
                    <div>{i18n.portfolio.gallery}</div>
                    <img alt={""} className={mobileGalleryClass} src={getImgUrl("media/open.svg")}/>
                </div>
            </div>}
            {(!galleryClosed || this.state.isCategory) && this.renderGallery()}
            {this.renderModal()}
            <div className="clear"></div>
            <Footer/>
        </div>
    }

    renderModal = () => {
        let gorizontal = true;
        if (isClientSide()) {
            gorizontal = window.innerHeight < window.innerWidth
        }
        return this.state.modal && this.state.modalData && this.modal(gorizontal);
    };

    modal = (gorizontal, getData) => {
        let modalStyle = style(gorizontal);
        return <div className={'modal-project-image'}>
            {!gorizontal && <Slide {...properties}>{this.state.modalData.map((i, index) => {
                let st = gorizontal ? 'box2' : 'box3';
                return <table>
                    <tr>
                        <td style={{verticalAlign: 'middle', height: '100vh', width: '100vw'}}>
                            <div key={'div' + index} style={modalStyle} className={st}>
                                <Image src={getImgUrl(i.image)}/>
                            </div>
                        </td>
                    </tr>
                </table>
            })}</Slide>}
            {gorizontal && <Fade  {...properties}>{this.state.modalData.map((i, index) => {
                let st = gorizontal ? 'box2' : 'box3';
                return <div key={'div' + index} style={modalStyle} className={st}>
                    <Image src={getImgUrl(i.image)}/>
                </div>
            })}</Fade>}
            {closeBtn(() => {
                this.setState({modal: false});
                isClientSide() && document.body.classList.remove('modal-open');
            }, "close-elem-ico", true)})
        </div>
    };


    getModalData = (img) => {
        let images = this.state.data.map(i => i.image);
        let index = images.indexOf(img.image);
        let result = this.state.data.map(i => i);
        let right = result.splice(0, index);
        right.forEach(i => result.push(i));
        return result;
    }
}

export default Project;
